/**
 * @generated SignedSource<<76edb4e2dce5e017f5733f440991c978>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DalMobileNav_links$data = {
  readonly accountNav: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DalAccountNav_accountNav">;
  } | null> | null;
  readonly mobileNav: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"NavItem_navItem">;
  } | null> | null;
  readonly " $fragmentType": "DalMobileNav_links";
};
export type DalMobileNav_links$key = {
  readonly " $data"?: DalMobileNav_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"DalMobileNav_links">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DalMobileNav_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "mobileNav",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NavItem_navItem"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "accountNav",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DalAccountNav_accountNav"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarLinks",
  "abstractKey": null
};

(node as any).hash = "0c519f850f47b19d1a9062ead02505ac";

export default node;

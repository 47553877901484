import { createContext } from 'react';
import serverVars from 'server-vars';

import { type DALSellerData, type DALUserData } from '../../../exports/UserDataTypes';

const sellerData: DALSellerData | undefined = serverVars.get('sellerData');
const masqueradeSeller: DALSellerData | undefined = serverVars.get('masqueradeSeller');
const userData: DALUserData | undefined = serverVars.get('userData');

export const UserContext = createContext<{
    seller?: DALSellerData;
    masqueradeSeller?: DALSellerData;
    user?: DALUserData;
    userOrSeller?: DALUserData | DALSellerData;
    isDealer: boolean;
    loginDealer: boolean;
    loginInternal: boolean;
    setHasNotifications: (arg0: boolean) => void;
    hasNotifications: boolean;
    isOnline: boolean;
    isPresenceEnabled: boolean;
}>({
    seller: sellerData,
    masqueradeSeller: masqueradeSeller,
    user: userData,
    userOrSeller: sellerData || masqueradeSeller || userData,
    isDealer: !!sellerData || !!masqueradeSeller,
    loginDealer: !!serverVars.get('loginDealer'),
    loginInternal: !!serverVars.get('loginInternal'),
    setHasNotifications: () => {},
    hasNotifications: false,
    isOnline: false,
    isPresenceEnabled: false,
});

/**
 * @generated SignedSource<<9a1beb30731e079eff2a5188a1dc26b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarItems_notifications$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItems_notifications" | "DesktopSideBarItem_notifications">;
  readonly " $fragmentType": "DesktopSideBarItems_notifications";
};
export type DesktopSideBarItems_notifications$key = {
  readonly " $data"?: DesktopSideBarItems_notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItems_notifications">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarItems_notifications",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItem_notifications"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarExpandingAreaItems_notifications"
    }
  ],
  "type": "DealerNavBarBadges",
  "abstractKey": null
};

(node as any).hash = "ea70c95fb94b839d87d361f0f85aa8f8";

export default node;

import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Link } from 'dibs-elements/exports/Link';
import { DesktopSideBarItem } from './DesktopSideBarItem';
import { trackNavInteraction } from '../../helpers/tracking';
import { DesktopSideBarItemVATReportLink } from './DesktopSideBarItemVATReportLink';
import { VAT_REPORT_MODAL_HASH } from '../VatReportModal/VatReportModal';

import { type DesktopSideBarExpandingAreaItem_seller$key } from './__generated__/DesktopSideBarExpandingAreaItem_seller.graphql';
import { type DesktopSideBarExpandingAreaItem_navItem$key } from './__generated__/DesktopSideBarExpandingAreaItem_navItem.graphql';
import { type DesktopSideBarExpandingAreaItem_notifications$key } from './__generated__/DesktopSideBarExpandingAreaItem_notifications.graphql';

type Props = {
    activeLink: string;
    seller: DesktopSideBarExpandingAreaItem_seller$key;
    navItem: DesktopSideBarExpandingAreaItem_navItem$key;
    notifications?: DesktopSideBarExpandingAreaItem_notifications$key | null;
};

const sellerFragment = graphql`
    fragment DesktopSideBarExpandingAreaItem_seller on Seller {
        ...DesktopSideBarItem_seller
        ...DesktopSideBarItemVATReportLink_seller
    }
`;

const navItemFragment = graphql`
    fragment DesktopSideBarExpandingAreaItem_navItem on NavBarElement {
        ...DesktopSideBarItem_navItem
        ...DesktopSideBarItemVATReportLink_navItem
        eventName
        interactionType
        href
        title
    }
`;

const notificationsFragment = graphql`
    fragment DesktopSideBarExpandingAreaItem_notifications on DealerNavBarBadges {
        ...DesktopSideBarItem_notifications
        ...DesktopSideBarItemVATReportLink_notifications
    }
`;

export const DesktopSideBarExpandingAreaItem: FC<Props> = ({
    activeLink,
    seller: sellerRef,
    navItem: navItemRef,
    notifications: notificationsRef,
}) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const navItem = useFragment(navItemFragment, navItemRef);
    const notifications = useFragment(notificationsFragment, notificationsRef);

    const { title, href, eventName, interactionType } = navItem;

    const isActiveItem = href === activeLink;

    return href === VAT_REPORT_MODAL_HASH ? (
        <DesktopSideBarItemVATReportLink
            seller={seller}
            navItem={navItem}
            notifications={notifications}
            isActive={isActiveItem}
        />
    ) : (
        <Link
            key={title}
            href={href || ''}
            onClick={() =>
                trackNavInteraction({
                    action: eventName,
                    label: eventName,
                    interactionType,
                })
            }
        >
            <DesktopSideBarItem
                seller={seller}
                navItem={navItem}
                notifications={notifications}
                isActive={isActiveItem}
            />
        </Link>
    );
};

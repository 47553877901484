import { type FC } from 'react';
import { trackNavInteraction } from '../../helpers/tracking';
import { useBrandLink } from './../userMenuOptions';
import { Link } from 'dibs-elements/exports/Link';
import Logo from 'dibs-logo/exports/Logo';

import classnames from 'classnames';
import dibsCss from 'dibs-css';

export const DesktopSideBarLogo: FC = () => {
    const brandLink = useBrandLink();

    return (
        <div className={classnames(dibsCss.pxMedium, dibsCss.pySmall, dibsCss.h60px)}>
            <Link
                dataTn="navbarBrandLink"
                onClick={() =>
                    trackNavInteraction({
                        action: 'logo',
                        label: 'none',
                        interactionType: 'global nav',
                    })
                }
                href={brandLink}
            >
                <Logo className={classnames(dibsCss.fillNoir)} />
            </Link>
        </div>
    );
};

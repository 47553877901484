import { Fragment, useState, useRef, type FC, useContext, useEffect } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { type ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';
import CirclePlus from 'dibs-icons/exports/legacy/CirclePlus';
import classnames from 'classnames';
import { IntlProvider } from 'dibs-react-intl';
import BulkItemUploadModalSuspense from 'dibs-bulk-item-upload/exports/BulkItemUploadModalSuspense';
import { type VerticalType } from 'dibs-bulk-item-upload/exports/types';
import { UserContext } from '../../UserContext';

import { CreateListingTooltipItems } from './CreateListingTooltip';
import useRefPropertyFactory from '../../hooks/useRefPropertyFactory';

import { trackNavInteraction } from '../../helpers/tracking';
import { ExpandingList } from '../ExpandingList';

import { Tooltip } from '../Tooltip';

import { type NavBarButtons_buttons$data } from './__generated__/NavBarButtons_buttons.graphql';
import { type NavBarButtons_seller$data } from './__generated__/NavBarButtons_seller.graphql';
import CoachmarkTooltip from 'dibs-coachmark-tooltip';
import { TooltipHeader } from 'dibs-elements/src/TooltipHeader';
import {
    getShouldShowOptIn,
    setPreviousOptInNotificationsData,
} from 'dibs-presence/exports/helpers';

import navItemStyles from './styles/NavItem.scss';
import styles from './styles/NavBarButtons.scss';

// eslint-disable-next-line react-hooks/rules-of-hooks
const useOffsetWidth = useRefPropertyFactory({ properties: 'offsetWidth' });

type Button = ArrayElement<NavBarButtons_buttons$data>;

type NavBarButtonProps = {
    index: number;
    isMobile: boolean;
    button: Button;
    seller: NavBarButtons_seller$data | null | undefined;
    separator: boolean;
};

const BULK_UPLOAD_STORAGE_KEY = 'bulkUploadNotifications';

// Only while seller presence coachmark is in place
const PRESENCE_OPT_IN_STORAGE_KEY = 'sellerPresenceOptInNotifications';

const NavBarButton: FC<NavBarButtonProps> = props => {
    const ref = useRef<HTMLLIElement>(null);
    const offsetWidth = useOffsetWidth(ref);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
    const userContext = useContext(UserContext);
    const sellerId = userContext.seller?.id;
    const { button, seller } = props;
    const { eventName } = button;
    const { createListingOptions = [] } = seller || {};
    const hasTooltip = (createListingOptions || []).length > 1;
    const vertical = (seller?.vertical || null) as VerticalType;
    const [showCoachmark, setShowCoachmark] = useState<boolean>(
        // Only while seller presence coachmark is in place
        !getShouldShowOptIn(PRESENCE_OPT_IN_STORAGE_KEY, true, true) &&
            getShouldShowOptIn(BULK_UPLOAD_STORAGE_KEY, true, true)
    );

    useEffect(() => {
        if (showCoachmark) {
            setShowCoachmark(true);
        }
    }, [showCoachmark]);

    const handleClose = (): void => {
        setPreviousOptInNotificationsData(BULK_UPLOAD_STORAGE_KEY);
        setShowCoachmark(false);
    };

    const onBulkUploadModalOpen = (): void => {
        setTooltipOpen(false);
        setBulkUploadModalOpen(true);
    };

    return (
        <li
            data-tn={`navBarButton-${props.index}`}
            key={button.id}
            ref={ref}
            className={classnames(
                navItemStyles.item,
                navItemStyles.noHover,
                styles.buttonItem,
                styles.navbarDropdown,
                styles.navBarButton,
                {
                    [navItemStyles.open]: tooltipOpen,
                    [navItemStyles.separator]: props.separator,
                }
            )}
        >
            <a
                className={classnames(styles.button, styles.createListingText)}
                data-tn={`navbar-button-${button.id}`}
                href={button.href || undefined}
                onClick={e => {
                    if (hasTooltip) {
                        e.preventDefault();
                        if (!tooltipOpen && eventName) {
                            trackNavInteraction({
                                action: `${eventName} dropdown`,
                                label: 'none',
                                interactionType: 'drop down',
                                isImpression: true,
                            });
                        }
                        setTooltipOpen(prev => !prev);
                    }

                    if (eventName) {
                        trackNavInteraction({
                            action: eventName,
                            label: 'none',
                            interactionType: button.interactionType,
                        });
                    }
                }}
            >
                <CirclePlus className={classnames(navItemStyles.circlePlus, styles.circlePlus)} />
                <span className={styles.label}>{button.text}</span>
            </a>

            {hasTooltip &&
                (props.isMobile ? (
                    <ExpandingList
                        dataTn={`navBarButton-${props.index}-expandingList`}
                        open={tooltipOpen}
                        className={styles.mobileCreateListingItemsContainer}
                        listClassName={styles.mobileCreateListingItemsList}
                    >
                        <CreateListingTooltipItems
                            listingTypes={createListingOptions || []}
                            openBulkUploadModal={onBulkUploadModalOpen}
                            isMobile
                        />
                    </ExpandingList>
                ) : (
                    <Tooltip container="ul" offsetWidth={offsetWidth} open={tooltipOpen}>
                        <CreateListingTooltipItems
                            listingTypes={createListingOptions || []}
                            openBulkUploadModal={onBulkUploadModalOpen}
                        />
                    </Tooltip>
                ))}

            {isBulkUploadModalOpen && (
                <IntlProvider locale="en-US">
                    <BulkItemUploadModalSuspense
                        vertical={vertical}
                        sellerId={sellerId || null}
                        onClose={() => setBulkUploadModalOpen(false)}
                    />
                </IntlProvider>
            )}

            {createListingOptions?.some(option => option?.dataTn === 'bulk-upload') &&
                showCoachmark && (
                    <div className={navItemStyles.coachmarkTooltip}>
                        <CoachmarkTooltip onClose={handleClose} direction="bottom" align="left">
                            <span className={navItemStyles.newBadge}>
                                <FormattedMessage
                                    id="dal.navItems.bulkUpload.tooltip.badge"
                                    defaultMessage="NEW"
                                />
                            </span>
                            <TooltipHeader
                                title={
                                    <FormattedMessage
                                        id="dal.navItems.bulkUpload.tooltip.title"
                                        defaultMessage="Import Multiple Listings at Once"
                                    />
                                }
                            />
                            <span className={navItemStyles.coachmarkContent}>
                                <FormattedMessage
                                    id="dal.navItems.bulkUpload.tooltip.content"
                                    defaultMessage="You can now upload a spreadsheet of your listings to quickly add a large number of items with their details."
                                />
                            </span>
                        </CoachmarkTooltip>
                    </div>
                )}
        </li>
    );
};

type Props = {
    isMobile: boolean;
    buttons: NavBarButtons_buttons$data | null | undefined;
    seller: NavBarButtons_seller$data | null | undefined;
};

export const NavBarButtons: FC<Props> = ({ isMobile, buttons, seller }) => {
    if (!buttons) {
        return null;
    }
    return (
        <Fragment>
            {buttons.map((button, i) => {
                return (
                    <NavBarButton
                        isMobile={isMobile}
                        key={`button=${button.id}`}
                        index={i}
                        button={button}
                        separator={!!buttons.length}
                        seller={seller}
                    />
                );
            })}
        </Fragment>
    );
};

export default createFragmentContainer(NavBarButtons, {
    buttons: graphql`
        fragment NavBarButtons_buttons on NavBarButton @relay(plural: true) {
            text
            href
            id
            eventName
            interactionType
        }
    `,
    seller: graphql`
        fragment NavBarButtons_seller on Seller {
            vertical
            createListingOptions(userId: $userId) {
                dataTn
                text
                url
                eventName
            }
        }
    `,
});

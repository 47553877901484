/**
 * @generated SignedSource<<cb4210e093be06f36fc3155d0b2eaf67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavDropdownItem_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountManagerComponent_seller" | "VatReportModal_seller">;
  readonly " $fragmentType": "NavDropdownItem_seller";
};
export type NavDropdownItem_seller$key = {
  readonly " $data"?: NavDropdownItem_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavDropdownItem_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavDropdownItem_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountManagerComponent_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VatReportModal_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "7de95d2d94a5067223b58c7f727ed3fb";

export default node;

import { useContext, useEffect, type FunctionComponent } from 'react';
import classNames from 'classnames';

import { addClassToNode, removeClassFromNode } from '../Common/domNodeModifiers';

import { ModalRefContext, type ModalContextProps } from '../ModalContainer/refContext';
import { Spinner } from '../Spinner';

import styles from './main.scss';

type ModalSpinnerProps = {
    dataTn?: string;
    isOpen: boolean;
    wrapperClass?: string;
};

export const ModalSpinner: FunctionComponent<ModalSpinnerProps> = props => {
    const { dataTn, isOpen, wrapperClass } = props;
    const modalRef = useContext<Partial<ModalContextProps>>(ModalRefContext);
    const modalNode = modalRef && modalRef.current;

    useEffect(() => {
        function lockScroll(): void {
            if (modalNode) {
                addClassToNode({ domNode: modalNode, className: styles.stopModalScroll });
            }
        }
        function unlockScroll(): void {
            if (modalNode) {
                removeClassFromNode({ domNode: modalNode, className: styles.stopModalScroll });
            }
        }
        if (isOpen) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return function cleanup() {
            unlockScroll();
        };
    }, [isOpen, modalNode]);

    if (isOpen) {
        let top = 0;
        if (modalNode) {
            top = modalNode.scrollTop;
        }
        const wrapperClasses = classNames(styles.spinnerWrapper, wrapperClass);
        return (
            <div className={wrapperClasses} data-tn={dataTn} style={{ top }}>
                <Spinner />
            </div>
        );
    }
    return null;
};

import { requestSubscription, graphql, type Environment } from 'react-relay/legacy';
import { type Disposable } from 'relay-runtime';
import {
    type presenceHeartbeatSubscription,
    type PresenceSubscriptionInput,
} from './__generated__/presenceHeartbeatSubscription.graphql';

export function presenceHeartbeat(
    environment: Environment,
    input: PresenceSubscriptionInput,
    onHeartbeatReceived: () => void
): Disposable {
    const subscription = graphql`
        subscription presenceHeartbeatSubscription($input: PresenceSubscriptionInput!) {
            presence(input: $input) {
                presenceResult {
                    recentOnlineDate
                }
            }
        }
    `;

    return requestSubscription<presenceHeartbeatSubscription>(environment, {
        subscription,
        variables: { input },
        onNext: () => {
            onHeartbeatReceived();
        },
    });
}

import { type FC, Suspense, useEffect, useState, lazy } from 'react';

import { type GetHelpModalProps } from '../src/types';
import { getGetHelpZendeskLocalStorageState } from '../src/getHelpZendesk/getHelpZendeskHelpers';
import { isGetHelpSellerModalZendeskVariant } from '../src/helpers';

const GetHelpModalSeller = lazy(
    () => import(/* webpackChunkName: "GetHelpModalSeller" */ '../src/GetHelpSeller')
);

const GetHelpModalSellerLazy: FC<GetHelpModalProps & { isPersistedModal?: boolean }> = ({
    isOpen,
    onClose,
    isPersistedModal,
    ...props
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const isModalAlreadyOpen = isGetHelpSellerModalZendeskVariant()
            ? !!getGetHelpZendeskLocalStorageState()?.isOpen
            : false;

        if (isOpen && (!isModalAlreadyOpen || isPersistedModal)) {
            setIsModalOpen(true);
        } else if (isOpen) {
            onClose();
        }
    }, [isOpen, onClose, isPersistedModal]);

    return isModalOpen ? (
        <Suspense fallback={null}>
            <GetHelpModalSeller
                {...props}
                isOpen
                onClose={() => {
                    onClose();
                    setIsModalOpen(false);
                }}
            />
        </Suspense>
    ) : null;
};

export default GetHelpModalSellerLazy;

/**
 * @generated SignedSource<<cad8af5f803c0a11fa97109e6df272b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarNavHeader_links$data = {
  readonly desktopSideNav: ReadonlyArray<{
    readonly hideSideNavTitle: boolean | null;
    readonly href: string | null;
    readonly items?: ReadonlyArray<{
      readonly hideSideNavTitle: boolean | null;
      readonly href: string | null;
      readonly title: string | null;
    } | null> | null;
    readonly title: string | null;
  } | null> | null;
  readonly " $fragmentType": "DesktopSideBarNavHeader_links";
};
export type DesktopSideBarNavHeader_links$key = {
  readonly " $data"?: DesktopSideBarNavHeader_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarNavHeader_links">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "href",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideSideNavTitle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarNavHeader_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "desktopSideNav",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NavBarListItem",
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "NavBarList",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarLinks",
  "abstractKey": null
};
})();

(node as any).hash = "a6e598d799cb5930f46b34e655cd92fd";

export default node;

import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import classNames from 'classnames';
import dibsCss from 'dibs-css';

import { type DesktopSideBarNavHeader_links$key } from './__generated__/DesktopSideBarNavHeader_links.graphql';

type Props = {
    links: DesktopSideBarNavHeader_links$key;
    activeLink: string;
};

const linksFragment = graphql`
    fragment DesktopSideBarNavHeader_links on NavBarLinks {
        desktopSideNav {
            href
            title
            hideSideNavTitle
            ... on NavBarList {
                items {
                    href
                    title
                    hideSideNavTitle
                }
            }
        }
    }
`;

export const DesktopSideBarNavHeader: FC<Props> = ({ links: linksRef, activeLink }) => {
    const links = useFragment(linksFragment, linksRef);
    const { desktopSideNav } = links;

    if (!desktopSideNav) {
        return null;
    }

    const activeItem = desktopSideNav.reduce<{
        href?: string | null;
        title?: string | null;
        hideSideNavTitle?: boolean | null;
    } | null>((active, navItem) => {
        if (navItem?.href === activeLink) {
            return navItem;
        }
        let activeSubItem;
        if (navItem?.items) {
            activeSubItem = navItem?.items?.find(item => item?.href === activeLink) || null;
            return activeSubItem ?? active;
        }
        return active;
    }, null);

    if (!activeItem || activeItem.hideSideNavTitle) {
        return null;
    }

    return (
        <div className={classNames(dibsCss.flex1, dibsCss.sassyFontHeader)}>{activeItem.title}</div>
    );
};

import { type FC, useState, useEffect } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { Tooltip } from 'dibs-elements/exports/Tooltip';
import { trackNavInteraction } from '../../helpers/tracking';
import QuestionMarkCircle from 'dibs-icons/exports/QuestionMarkCircle';
import { DesktopSideBarNavSupportItem } from './DesktopSideBarNavSupportItem';

import classNames from 'classnames';
import dibsCss from 'dibs-css';

import { type DesktopSideBarNavHelp_seller$key } from './__generated__/DesktopSideBarNavHelp_seller.graphql';
import { type DesktopSideBarNavHelp_links$key } from './__generated__/DesktopSideBarNavHelp_links.graphql';

type Props = {
    seller: DesktopSideBarNavHelp_seller$key;
    links: DesktopSideBarNavHelp_links$key;
};

const sellerFragment = graphql`
    fragment DesktopSideBarNavHelp_seller on Seller {
        sellerRep {
            email
        }
    }
`;

const linksFragment = graphql`
    fragment DesktopSideBarNavHelp_links on NavBarLinks {
        desktopSideNav {
            dataTn
            eventName
            ... on NavBarList {
                items {
                    href
                    ...DesktopSideBarNavSupportItem_item
                }
            }
        }
    }
`;

export const DesktopSideBarNavHelp: FC<Props> = ({ seller: sellerRef, links: linksRef }) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const links = useFragment(linksFragment, linksRef);
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const supportMenu = links?.desktopSideNav?.find(link => link?.dataTn === 'support');
    const supportItems = supportMenu?.items;
    const accountManagerEmail = seller?.sellerRep?.email;
    const eventName = supportMenu?.eventName;

    useEffect(() => {
        if (isMenuVisible && eventName) {
            trackNavInteraction({
                action: `${eventName} dropdown`,
                label: eventName,
                interactionType: 'drop down',
                isImpression: true,
            });
        }
    }, [isMenuVisible, eventName]);

    if (!supportItems?.length) {
        return null;
    }

    return (
        <div
            onClick={() => setIsMenuVisible(prev => !prev)}
            className={classNames(
                dibsCss.relative,
                dibsCss.flex,
                dibsCss.flexCol,
                dibsCss.itemsCenter,
                dibsCss.sassyFontBodySmallHeavy,
                dibsCss.cursorPointer
            )}
        >
            <QuestionMarkCircle variation="20" />
            <FormattedMessage id="dal.header.help" defaultMessage="Help" />
            <Tooltip
                isVisible={isMenuVisible}
                direction="bottom"
                paddingSize="none"
                closeOnOutsideClick
                onClose={() => setIsMenuVisible(false)}
            >
                <div
                    className={classNames(
                        dibsCss.flex,
                        dibsCss.flexCol,
                        dibsCss.gapXsmall,
                        dibsCss.pSmall
                    )}
                >
                    {supportItems.map(
                        item => item && <DesktopSideBarNavSupportItem key={item.href} item={item} />
                    )}
                    {accountManagerEmail && (
                        <div
                            className={classNames(
                                dibsCss.flex,
                                dibsCss.flexCol,
                                dibsCss.ptXsmall,
                                dibsCss.border0,
                                dibsCss.borderT,
                                dibsCss.borderSolid,
                                dibsCss.borderDolphin
                            )}
                        >
                            <FormattedMessage
                                id="dal.navItem.partnerManager.email"
                                defaultMessage="<wrapper>Partner Manager:</wrapper><wrapper>{email}</wrapper>"
                                values={{
                                    email: accountManagerEmail,
                                    wrapper: content => {
                                        return <span>{content}</span>;
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
            </Tooltip>
        </div>
    );
};

import { type FC, Suspense, lazy } from 'react';
import { type VerticalType } from './types';
import { FormattedMessage } from 'dibs-react-intl';
import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';
import { ModalSpinner } from 'dibs-elements/exports/ModalSpinner';
import { ModalBody } from 'dibs-elements/exports/ModalBody';

import { ErrorBoundary } from 'dibs-error-tools/ErrorBoundary';

const BulkItemUploadModalRenderer = lazy(
    () =>
        import(
            /* webpackChunkName: "BulkItemUploadModalRenderer" */ './BulkItemUploadModalRenderer'
        )
);

type Props = {
    vertical: VerticalType;
    sellerId: string | null;
    onClose: () => void;
};

const BulkItemUploadModalSuspense: FC<Props> = ({ vertical, sellerId, onClose }) => {
    return (
        <ModalContainer
            isOpen
            onClose={onClose}
            modalPosition="center"
            dataTn="bulk-item-upload-modal"
            modalSize="default"
        >
            <ModalCloseButton onClick={onClose} dataTn="bulk-item-upload-modal-close-button" />
            <ErrorBoundary
                fallback={() => (
                    <ModalBody>
                        <div>
                            <FormattedMessage
                                id="bulkItemUpload.modal.error"
                                defaultMessage="There was an error loading. Please try again."
                            />
                        </div>
                    </ModalBody>
                )}
            >
                <Suspense
                    fallback={<ModalSpinner dataTn="bulk-item-upload-modal-spinner" isOpen />}
                >
                    <BulkItemUploadModalRenderer
                        vertical={vertical}
                        sellerId={sellerId}
                        onClose={onClose}
                    />
                </Suspense>
            </ErrorBoundary>
        </ModalContainer>
    );
};

export default BulkItemUploadModalSuspense;

import { type FC, useState, useEffect } from 'react';
import { useFragment, graphql } from 'react-relay';
import { DesktopSideBarItem } from './DesktopSideBarItem';
import { DesktopSideBarExpandingAreaItem } from './DesktopSideBarExpandingAreaItem';
import { ExpandingArea } from 'dibs-elements/exports/ExpandingArea';
import { trackNavInteraction } from '../../helpers/tracking';

import styles from './styles/DesktopSideBarExpandingAreaItems.scss';
import classnames from 'classnames';
import dibsCss from 'dibs-css';

import { type DesktopSideBarExpandingAreaItems_seller$key } from './__generated__/DesktopSideBarExpandingAreaItems_seller.graphql';
import { type DesktopSideBarExpandingAreaItems_navItem$key } from './__generated__/DesktopSideBarExpandingAreaItems_navItem.graphql';
import { type DesktopSideBarExpandingAreaItems_notifications$key } from './__generated__/DesktopSideBarExpandingAreaItems_notifications.graphql';

type Props = {
    activeLink: string;
    seller: DesktopSideBarExpandingAreaItems_seller$key;
    navItem: DesktopSideBarExpandingAreaItems_navItem$key;
    notifications?: DesktopSideBarExpandingAreaItems_notifications$key | null;
};

const sellerFragment = graphql`
    fragment DesktopSideBarExpandingAreaItems_seller on Seller {
        ...DesktopSideBarItem_seller
        ...DesktopSideBarExpandingAreaItem_seller
    }
`;

const navItemFragment = graphql`
    fragment DesktopSideBarExpandingAreaItems_navItem on NavBarElement {
        ...DesktopSideBarItem_navItem
        eventName
        ... on NavBarList {
            items {
                ...DesktopSideBarExpandingAreaItem_navItem
                href
                title
            }
        }
    }
`;

const notificationsFragment = graphql`
    fragment DesktopSideBarExpandingAreaItems_notifications on DealerNavBarBadges {
        ...DesktopSideBarItem_notifications
        ...DesktopSideBarExpandingAreaItem_notifications
    }
`;

export const DesktopSideBarExpandingAreaItems: FC<Props> = ({
    activeLink,
    seller: sellerRef,
    navItem: navItemRef,
    notifications: notificationsRef,
}) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const navItem = useFragment(navItemFragment, navItemRef);
    const notifications = useFragment(notificationsFragment, notificationsRef);
    const [expanded, setExpanded] = useState(
        navItem?.items?.some(i => i?.href === activeLink) || false
    );

    const { eventName } = navItem;

    useEffect(() => {
        if (expanded) {
            trackNavInteraction({
                action: `${eventName} dropdown`,
                label: eventName,
                interactionType: 'drop down',
                isImpression: true,
            });
        }
    }, [expanded, eventName]);

    if (!navItem) {
        return null;
    }

    return (
        <ExpandingArea
            label={
                <DesktopSideBarItem
                    seller={seller}
                    navItem={navItem}
                    notifications={notifications}
                />
            }
            labelClass={styles.expandingAreaLabel}
            arrowClass={classnames(styles.expandingAreaArrow, dibsCss.mrXsmall)}
            onClick={() => setExpanded(prev => !prev)}
            expanded={expanded}
            showArrow
        >
            {navItem.items?.map(item => {
                if (!item) {
                    return null;
                }
                return (
                    <DesktopSideBarExpandingAreaItem
                        key={item.title}
                        seller={seller}
                        navItem={item}
                        notifications={notifications}
                        activeLink={activeLink}
                    />
                );
            })}
        </ExpandingArea>
    );
};

import serverVars from 'server-vars';

type SellerQueryVariables = {
    sellerPk: string;
    dotComHost: string;
    userId: string;
    hyperwalletLink: string;
    hasSellerPk: boolean;
};

export const getSellerQueryVariables = (): SellerQueryVariables => {
    const userId = serverVars.get('userData.id') || null;
    return {
        sellerPk: serverVars.get('sellerData.id') || '',
        dotComHost: serverVars.get('constants.DOTCOM_HOME_URL'),
        userId,
        hyperwalletLink: serverVars.get('constants.payoutsLink') || '',
        hasSellerPk: !!serverVars.get('sellerData.id'),
    };
};

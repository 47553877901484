// dibs-css must be imported before any packages that import dibs-css to guarantee dibs-css is
// bundled correctly in DAL.
import 'dibs-css/exports/dibs-css.module.css';

import { createRoot } from 'react-dom/client';
import { QueryRenderer, graphql } from 'react-relay/legacy';
import { IntlProvider } from 'dibs-react-intl';
import serverVars from 'server-vars';
import { sessionStorage } from 'dibs-browser-storage';
import { initChat } from 'dibs-contact-1stdibs/exports/liveChat';
import {
    SALESFORCE_CHAT_KEY,
    CHAT_TYPE_MAP,
} from 'dibs-contact-1stdibs/exports/liveChat/constants';
import NavHeader from './components/NavHeader';
import contextFactory, { type RenderNavbar } from './navBarContextFactory';
import { SideBarNavTestProvider } from 'dibs-admin-layout/exports/useSideBarNavTest';

import { type dealerQuery } from './__generated__/dealerQuery.graphql';

import '../common';
import '../../scss/entries/dealers.scss';
import { getSellerQueryVariables } from '../../../exports/getSellerQueryVariables';

const navDomElement = document.getElementById('dibs-nav');
const root = createRoot(navDomElement as HTMLElement);

const renderNavbar: RenderNavbar = (context, { refetchIndex = 0, customBannerText = '' }) => {
    const variables = getSellerQueryVariables();

    if (sessionStorage.getItem(SALESFORCE_CHAT_KEY)) {
        initChat({ chatType: CHAT_TYPE_MAP.seller, environment: context.relayEnvironment });
    }

    return root.render(
        <IntlProvider locale={serverVars.get('locale')} messages={serverVars.get('messages')}>
            <SideBarNavTestProvider>
                <QueryRenderer<dealerQuery>
                    query={graphql`
                        query dealerQuery(
                            $sellerPk: String = ""
                            $userId: String = ""
                            $dotComHost: String!
                            $hyperwalletLink: String!
                            $customBannerText: String = ""
                            $renderNotifications: Boolean = false
                            $hasSellerPk: Boolean!
                            $isDealer: Boolean = true
                        ) {
                            viewer {
                                ...NavHeader_viewer
                            }
                        }
                    `}
                    variables={variables}
                    environment={context.relayEnvironment}
                    render={({ props }) => {
                        const viewer = props?.viewer;
                        if (!viewer) {
                            return null;
                        }
                        return (
                            <NavHeader
                                viewer={viewer}
                                refetchIndex={refetchIndex}
                                customBannerText={customBannerText}
                            />
                        );
                    }}
                />
            </SideBarNavTestProvider>
        </IntlProvider>
    );
};

window.navbarContext = contextFactory(renderNavbar);

if (navDomElement) {
    renderNavbar(window.navbarContext(), { refetchIndex: 0, customBannerText: '' });
}

import { graphql, type Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';
import { getSellerId } from 'dibs-cookie-jar';
import { type presenceMutation as _presenceMutation } from './__generated__/presenceMutation.graphql';

const mutation = graphql`
    mutation presenceMutation($input: PresenceUpdateHeartbeatInput!) {
        presenceUpdateHeartbeat(input: $input) {
            __typename
        }
    }
`;

const presenceMutation = (environment: Environment): void => {
    const id = getSellerId(document.cookie) || '';

    commitMutation<_presenceMutation>(environment, {
        mutation,
        variables: {
            input: {
                userOrSellerId: id,
            },
        },
    });
};

export default presenceMutation;

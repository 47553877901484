import { type FC, type ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import Nav from './Nav';
import { type InternalWrapper_viewer$data } from './__generated__/InternalWrapper_viewer.graphql';

type Props = {
    viewer: InternalWrapper_viewer$data | null | undefined;
    isMobile: boolean;
    openNavBar: boolean;
    customHeader: ReactNode;
    openUserMenu: boolean;
};

const InternalWrapper: FC<Props> = ({
    viewer,
    isMobile,
    openNavBar,
    customHeader,
    openUserMenu,
}) => {
    const links = viewer?.internalNavBarLinks?.links || null;
    return (
        <Nav
            isMobile={isMobile}
            openNavBar={openNavBar}
            openUserMenu={openUserMenu}
            customHeader={customHeader}
            internalLinks={links}
            links={null}
            notifications={null}
            buttons={null}
            seller={null}
        />
    );
};

export default createFragmentContainer(InternalWrapper, {
    viewer: graphql`
        fragment InternalWrapper_viewer on Viewer {
            internalNavBarLinks(userId: $userId, reduceShortGroups: true) {
                links {
                    ...Nav_internalLinks
                }
            }
        }
    `,
});

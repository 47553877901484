/**
 * @generated SignedSource<<fbb5c5f5fb003deb7f375af83adc6917>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarItems_links$data = {
  readonly desktopSideNav: ReadonlyArray<{
    readonly dataTn: string | null;
    readonly eventName: string | null;
    readonly href: string | null;
    readonly interactionType: string | null;
    readonly items?: ReadonlyArray<{
      readonly title: string | null;
    } | null> | null;
    readonly title: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItems_navItem" | "DesktopSideBarItem_navItem">;
  } | null> | null;
  readonly " $fragmentType": "DesktopSideBarItems_links";
};
export type DesktopSideBarItems_links$key = {
  readonly " $data"?: DesktopSideBarItems_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItems_links">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarItems_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "desktopSideNav",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DesktopSideBarItem_navItem"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DesktopSideBarExpandingAreaItems_navItem"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NavBarListItem",
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "NavBarList",
          "abstractKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "href",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataTn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eventName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interactionType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarLinks",
  "abstractKey": null
};
})();

(node as any).hash = "1320c3f9fab0141562de937dcb0b029f";

export default node;

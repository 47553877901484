import { useFragment, graphql } from 'react-relay';
import {
    hasRecommendationSnoozeExpired,
    getRecommendationSnoozeTime,
} from './recommendationsRedDot';

import { type useNotificationsConfig_seller$key } from './__generated__/useNotificationsConfig_seller.graphql';
import { type useNotificationsConfig_notifications$key } from './__generated__/useNotificationsConfig_notifications.graphql';

type UseNotificationsConfigType = (args: {
    seller?: useNotificationsConfig_seller$key | null;
    notifications?: useNotificationsConfig_notifications$key | null;
}) => Record<
    'ordersIcon' | 'messageIcon' | 'listingsIcon' | 'recommendationsIcon' | 'advertisingIcon',
    {
        count: [string, boolean];
        dataTn: string;
        showCount: boolean;
    } | null
>;

const sellerFragment = graphql`
    fragment useNotificationsConfig_seller on Seller {
        serviceId
    }
`;

const notificationsFragment = graphql`
    fragment useNotificationsConfig_notifications on DealerNavBarBadges {
        listings
        orders
        messages
        recommendations
    }
`;

const MAX_NOTIFICATIONS_COUNT = 50;

/**
 * returns tuple: [0] formatted count;
 * [1] boolean indicating if the notification
 * ceiling has been hit
 */
function formatCount(count: unknown): [string, boolean] {
    const countNumber = Number(count) || 0;

    if (countNumber > MAX_NOTIFICATIONS_COUNT) {
        return [`${MAX_NOTIFICATIONS_COUNT}+`, true];
    }
    return [countNumber.toString(), false];
}

export const useNotificationsConfig: UseNotificationsConfigType = ({
    seller: sellerRef,
    notifications: notificationsRef,
}) => {
    const seller = useFragment<useNotificationsConfig_seller$key>(sellerFragment, sellerRef);
    const notifications = useFragment<useNotificationsConfig_notifications$key>(
        notificationsFragment,
        notificationsRef
    );

    const { serviceId: sellerId } = seller || {};
    const { listings, orders, messages, recommendations } = notifications || {
        listings: null,
        orders: null,
        messages: null,
        recommendations: null,
    };

    const recommendationsSnoozeExpired = hasRecommendationSnoozeExpired(sellerId || '');
    const recommendationsSnoozedDate = getRecommendationSnoozeTime(sellerId || '');

    return {
        ordersIcon: {
            count: formatCount(orders),
            dataTn: 'orders',
            showCount: true,
        },
        messageIcon: {
            count: formatCount(messages),
            dataTn: 'messages',
            showCount: true,
        },
        listingsIcon: {
            count: formatCount(listings),
            dataTn: 'listings',
            showCount: true,
        },
        recommendationsIcon: recommendationsSnoozeExpired
            ? {
                  count: formatCount(recommendations),
                  dataTn: 'recommendations',
                  showCount: false,
                  isSnoozed: !recommendationsSnoozeExpired,
                  snoozeDate: recommendationsSnoozedDate
                      ? new Date(recommendationsSnoozedDate)
                      : null,
              }
            : null,
        advertisingIcon: localStorage.getItem('SELLER_ADVERTISING_PAGE_SEEN')
            ? null
            : {
                  count: formatCount(1),
                  dataTn: 'advertising',
                  showCount: false,
              },
    };
};

/**
 * @generated SignedSource<<add24f9dc549abfe8834cbebaa309d5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSendPresenceHeartbeats_seller$data = {
  readonly sellerPreferences: {
    readonly showOnlineStatus: boolean | null;
  } | null;
  readonly " $fragmentType": "useSendPresenceHeartbeats_seller";
};
export type useSendPresenceHeartbeats_seller$key = {
  readonly " $data"?: useSendPresenceHeartbeats_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSendPresenceHeartbeats_seller">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSendPresenceHeartbeats_seller"
};

(node as any).hash = "9279caa7bbafc8e894c57732491c36f6";

export default node;

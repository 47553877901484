/**
 * @generated SignedSource<<001cab937666a3eea0a90fa542bbc033>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarItem_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItemBadge_seller">;
  readonly " $fragmentType": "DesktopSideBarItem_seller";
};
export type DesktopSideBarItem_seller$key = {
  readonly " $data"?: DesktopSideBarItem_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItem_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarItem_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItemBadge_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "ab819fdebbc9567911eeebd6dc9c27c6";

export default node;

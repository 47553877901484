/**
 * @generated SignedSource<<fc330dbbe2564e5e3d1449c295a498ee>>
 * @relayHash f0fe32fc06604bbc1dc451cbf8442d66
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/504.0.0-2025-04-01T10:32:06.638Z/presenceHeartbeatSubscription

import { ConcreteRequest } from 'relay-runtime';
export type PresenceSubscriptionInput = {
  userOrSellerId: string;
};
export type presenceHeartbeatSubscription$variables = {
  input: PresenceSubscriptionInput;
};
export type presenceHeartbeatSubscription$data = {
  readonly presence: {
    readonly presenceResult: {
      readonly recentOnlineDate: string | null;
    } | null;
  } | null;
};
export type presenceHeartbeatSubscription = {
  response: presenceHeartbeatSubscription$data;
  variables: presenceHeartbeatSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PresenceSubscriptionPayload",
    "kind": "LinkedField",
    "name": "presence",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PresenceResults",
        "kind": "LinkedField",
        "name": "presenceResult",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recentOnlineDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "presenceHeartbeatSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscriptions",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "presenceHeartbeatSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/504.0.0-2025-04-01T10:32:06.638Z/presenceHeartbeatSubscription",
    "metadata": {},
    "name": "presenceHeartbeatSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "3870fa3976639153509218fc34d8eea4";

export default node;

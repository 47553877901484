/**
 * @generated SignedSource<<7c48f9e851f53e5c080fbdf3c09d9ac3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarExpandingAreaItems_navItem$data = {
  readonly eventName: string | null;
  readonly items?: ReadonlyArray<{
    readonly href: string | null;
    readonly title: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItem_navItem">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItem_navItem">;
  readonly " $fragmentType": "DesktopSideBarExpandingAreaItems_navItem";
};
export type DesktopSideBarExpandingAreaItems_navItem$key = {
  readonly " $data"?: DesktopSideBarExpandingAreaItems_navItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItems_navItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarExpandingAreaItems_navItem",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItem_navItem"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventName",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NavBarListItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarExpandingAreaItem_navItem"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "href",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "NavBarList",
      "abstractKey": null
    }
  ],
  "type": "NavBarElement",
  "abstractKey": "__isNavBarElement"
};

(node as any).hash = "f3a50ae95914c8a2baaa81954c04454f";

export default node;

/**
 * @generated SignedSource<<8d88647b62af9a41f29b23d9863e296b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarExpandingAreaItem_navItem$data = {
  readonly eventName: string | null;
  readonly href: string | null;
  readonly interactionType: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItemVATReportLink_navItem" | "DesktopSideBarItem_navItem">;
  readonly " $fragmentType": "DesktopSideBarExpandingAreaItem_navItem";
};
export type DesktopSideBarExpandingAreaItem_navItem$key = {
  readonly " $data"?: DesktopSideBarExpandingAreaItem_navItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItem_navItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarExpandingAreaItem_navItem",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItem_navItem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItemVATReportLink_navItem"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interactionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "NavBarElement",
  "abstractKey": "__isNavBarElement"
};

(node as any).hash = "9cb2ca07b46c24c0ab48b7ebb94bb91a";

export default node;

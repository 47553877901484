/**
 * @generated SignedSource<<48897dfd428c0ac466fd2d828ec59a52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavItemBadge_notifications$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useNotificationsConfig_notifications">;
  readonly " $fragmentType": "NavItemBadge_notifications";
};
export type NavItemBadge_notifications$key = {
  readonly " $data"?: NavItemBadge_notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavItemBadge_notifications">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavItemBadge_notifications",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useNotificationsConfig_notifications"
    }
  ],
  "type": "DealerNavBarBadges",
  "abstractKey": null
};

(node as any).hash = "82a47fe5efe3b1cf7e6d0e0bbf85493b";

export default node;

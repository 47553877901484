/**
 * @generated SignedSource<<84bd381f37bee15f58a2bf5f7c13ad59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavItem_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountManagerComponent_seller" | "NavDropdownItem_seller" | "NavItemBadge_seller" | "VatReportModal_seller" | "useNotificationsConfig_seller">;
  readonly " $fragmentType": "NavItem_seller";
};
export type NavItem_seller$key = {
  readonly " $data"?: NavItem_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavItem_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavItem_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useNotificationsConfig_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavDropdownItem_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountManagerComponent_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VatReportModal_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavItemBadge_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "0076a89ccb7ed1ef22ba302ab6ab366f";

export default node;

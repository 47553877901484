import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { DesktopSideBarItem } from './DesktopSideBarItem';
import { Link } from 'dibs-elements/exports/Link';
import { trackNavInteraction } from '../../helpers/tracking';
import { VatReportModal } from '../VatReportModal/VatReportModal';
import { useVatReportModalOpenState } from '../NavItems/helpers';

import { type DesktopSideBarItemVATReportLink_seller$key } from './__generated__/DesktopSideBarItemVATReportLink_seller.graphql';
import { type DesktopSideBarItemVATReportLink_navItem$key } from './__generated__/DesktopSideBarItemVATReportLink_navItem.graphql';
import { type DesktopSideBarItemVATReportLink_notifications$key } from './__generated__/DesktopSideBarItemVATReportLink_notifications.graphql';

type Props = {
    seller: DesktopSideBarItemVATReportLink_seller$key;
    navItem: DesktopSideBarItemVATReportLink_navItem$key;
    notifications?: DesktopSideBarItemVATReportLink_notifications$key | null;
    isActive?: boolean;
};

const sellerFragment = graphql`
    fragment DesktopSideBarItemVATReportLink_seller on Seller {
        ...DesktopSideBarItem_seller
        ...VatReportModal_seller
    }
`;

const navItemFragment = graphql`
    fragment DesktopSideBarItemVATReportLink_navItem on NavBarElement {
        ...DesktopSideBarItem_navItem
        eventName
        interactionType
        href
        title
    }
`;

const notificationsFragment = graphql`
    fragment DesktopSideBarItemVATReportLink_notifications on DealerNavBarBadges {
        ...DesktopSideBarItem_notifications
    }
`;

export const DesktopSideBarItemVATReportLink: FC<Props> = ({
    seller: sellerRef,
    navItem: navItemRef,
    notifications: notificationsRef,
    isActive,
}) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const navItem = useFragment(navItemFragment, navItemRef);
    const notifications = useFragment(notificationsFragment, notificationsRef);

    const { title, href, eventName, interactionType } = navItem;

    const { isVatReportModalOpen, setIsVatReportModalOpen, vatReportModalHandler } =
        useVatReportModalOpenState(href || '');

    return (
        <Link
            key={title}
            href={href || ''}
            onClick={event => {
                trackNavInteraction({
                    action: eventName,
                    label: eventName,
                    interactionType,
                });
                vatReportModalHandler(event);
            }}
        >
            <DesktopSideBarItem
                seller={seller}
                navItem={navItem}
                notifications={notifications}
                isActive={isActive}
            />
            <VatReportModal
                seller={seller}
                isOpen={isVatReportModalOpen}
                onClose={() => setIsVatReportModalOpen(false)}
            />
        </Link>
    );
};

/**
 * @generated SignedSource<<9b122e02a801c077bb9b1280e4d89906>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopNav_links$data = {
  readonly accountNav: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DalAccountNav_accountNav">;
  } | null> | null;
  readonly desktopNav: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"NavItem_navItem">;
  } | null> | null;
  readonly " $fragmentType": "DesktopNav_links";
};
export type DesktopNav_links$key = {
  readonly " $data"?: DesktopNav_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopNav_links">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopNav_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "desktopNav",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NavItem_navItem"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "accountNav",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DalAccountNav_accountNav"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarLinks",
  "abstractKey": null
};

(node as any).hash = "b862ccf1f5cf11b7c41012d972b7bc1f";

export default node;

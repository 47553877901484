import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type MegaphoneProps = {
    className?: string;
};

const Megaphone: FunctionComponent<MegaphoneProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'megaphone'}
            viewBox="0 0 250 250"
        >
            <path d="M250.1 120.6c0-21.1-16-38.5-36.5-40.8V23.3c0-1.7-.9-3.2-2.5-4-1.5-.8-3.3-.6-4.7.4-11.3 8.2-21 15.8-29.5 22.4-35.3 27.6-53 41.4-109 41.4H40.5c-10.7 0-20.8 4.2-28.5 11.8C4.4 103 .2 113.1.2 123.9c0 16.4 9.9 31 24.8 37.1l8.3 55.2v.2c1.7 8.7 9.4 14.8 17.9 14.8 1.2 0 2.3-.1 3.5-.3 9.8-2 16.3-11.5 14.4-21.3l-6.5-45.3h5.1c6.2 0 11.8.2 17.2.5 22.9 1.4 39.8 6 56.3 15.5 11.4 6.5 22 14.9 35.5 25.4 8.5 6.7 18.1 14.2 29.5 22.5.8.6 1.7.9 2.6.9.7 0 1.4-.2 2-.5 1.5-.8 2.5-2.3 2.5-4v-63c20.7-2.5 36.8-19.9 36.8-41zM60.3 210.8v.2c1 5-2.3 9.9-7.3 10.9s-9.9-2.3-10.9-7.2l-7.6-51.1c.2 0 .4.1.6.1.2 0 .4.1.7.1.3 0 .6.1.9.1.2 0 .4 0 .6.1.3 0 .7 0 1.1.1h15.2l6.7 46.7zm-2.8-55.7h-17c-3.5 0-6.8-.6-10.1-1.7-12.7-4.3-21.3-16.1-21.3-29.6 0-8.3 3.3-16.2 9.2-22.1 5.9-5.9 13.8-9.2 22.1-9.2h27.3c4.5 0 8.8-.1 13-.3v63.1c-4.1-.2-8.4-.3-13-.3H57.5zm124.9 43.4c-13.2-10.4-24.7-19.3-36.6-26.1-16.8-9.6-33.7-14.5-56-16.3V91.7c42.8-3.6 61.7-18.3 92.6-42.5 6.6-5.2 13.9-10.8 22.1-17v183.2c-8.2-6.1-15.5-11.8-22.1-16.9zm31.1-46.2V88.8C229 91 241 104.4 241 120.5c.1 16.2-11.9 29.6-27.5 31.8z" />
        </svg>
    );
};
export default Megaphone;

import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { useFragment, graphql } from 'react-relay';
import typeStyles from '../styles/Type.scss';
import { type AccountManagerComponent_seller$key } from './__generated__/AccountManagerComponent_seller.graphql';

type Props = {
    seller: AccountManagerComponent_seller$key | null | undefined;
};

const AccountManagerComponent: FC<Props> = ({ seller: sellerRef }) => {
    const seller = useFragment(
        graphql`
            fragment AccountManagerComponent_seller on Seller {
                sellerRep {
                    email
                }
            }
        `,
        sellerRef
    );

    if (!seller?.sellerRep?.email) {
        return null;
    }
    const { sellerRep } = seller;
    const email = sellerRep?.email;

    return (
        <div className={typeStyles.body}>
            <FormattedMessage
                id="dal.navItem.AccountManager.email"
                defaultMessage="Partner Manager: <wrapper>{email}</wrapper>"
                values={{
                    email,
                    wrapper: content => {
                        return <div className={typeStyles.email}>{content}</div>;
                    },
                }}
            />
        </div>
    );
};

export default AccountManagerComponent;

import { useState, useRef, useEffect } from 'react';
import { graphql, readInlineData, type Environment } from 'react-relay/legacy';
import { type Disposable } from 'relay-runtime';
import { presenceHeartbeat } from './presenceHeartbeatSubscription';
import {
    type useIsSellerOnline_viewer$key,
    type useIsSellerOnline_viewer$data,
} from './__generated__/useIsSellerOnline_viewer.graphql';
import { type useIsSellerOnline_seller$key } from './__generated__/useIsSellerOnline_seller.graphql';
import { USER_ONLINE_INTERVAL } from '../constants';
import { usePageVisibilityTimeout } from 'dibs-elements/exports/usePageVisibilityTimeout';
import { SUBSCRIPTIONS_TAB_TIMEOUT } from 'dibs-constants/exports/subscriptionsTabTimeout';
import { isMasquerading } from 'dibs-cookie-jar';

export const checkIsOnline = (recentOnlineDate: string): boolean => {
    if (!recentOnlineDate) {
        return false;
    }
    const lastOnline = new Date(recentOnlineDate);
    const now = new Date();

    return now.valueOf() - lastOnline.valueOf() < USER_ONLINE_INTERVAL;
};

const presenceViewerQuery = graphql`
    fragment useIsSellerOnline_viewer on Viewer @inline {
        presenceFeatureFlag: featureFlag(feature: "presence")
        presenceRolloutPercentage: config(type: "graphQLConfig", key: "presenceRolloutPercentage")
    }
`;

const presenceSellerQuery = graphql`
    fragment useIsSellerOnline_seller on Seller @inline {
        serviceId
        presence {
            recentOnlineDate
        }
        sellerPreferences {
            showOnlineStatus
        }
    }
`;

type Props = {
    environment: Environment;
    viewer: useIsSellerOnline_viewer$key | null | undefined;
    seller?: useIsSellerOnline_seller$key | null | undefined;
};

export const useIsSellerOnline = ({ environment, viewer, seller }: Props): boolean => {
    const heartbeatDisposable = useRef<Disposable | null>(null);
    const timeout = useRef(0);
    const isVisible = usePageVisibilityTimeout(SUBSCRIPTIONS_TAB_TIMEOUT);
    let viewerResponse: useIsSellerOnline_viewer$data | undefined;
    const [isOnline, setIsOnline] = useState(false);
    let isPresenceEnabled = false;
    let id = '';

    if (viewer) {
        viewerResponse = readInlineData(presenceViewerQuery, viewer);
    }

    const setIsOnlineWithTimeout = (): void => {
        setIsOnline(true);
        window.clearTimeout(timeout.current);
        timeout.current = window.setTimeout(() => {
            setIsOnline(false);
        }, USER_ONLINE_INTERVAL);
    };

    if (viewerResponse?.presenceFeatureFlag && seller) {
        const { presence, sellerPreferences, serviceId } = readInlineData(
            presenceSellerQuery,
            seller
        );

        id = serviceId || '';
        const showOnlineStatus = !!sellerPreferences?.showOnlineStatus;

        // masquerading and the current seller are always eligible, but if called
        // check if the user has permission to query seller's presence
        const isMasked = document && isMasquerading(document.cookie);

        const isEligible = showOnlineStatus && !isMasked;

        // check the last time a seller was online and comapare it to USER_ONLINE_INTERVAL
        if (isEligible) {
            const recentOnlineDate = presence?.recentOnlineDate || '';
            isPresenceEnabled = true;
            if (!isOnline && checkIsOnline(recentOnlineDate)) {
                setIsOnlineWithTimeout();
            }
        }
    }

    // Subscribe to heartbeats
    useEffect(() => {
        if (isPresenceEnabled && isVisible) {
            heartbeatDisposable.current = presenceHeartbeat(
                environment,
                {
                    userOrSellerId: id,
                },
                setIsOnlineWithTimeout
            );
        }
        return () => {
            heartbeatDisposable.current?.dispose();
        };
    }, [environment, id, isPresenceEnabled, isVisible]);

    return isPresenceEnabled && isOnline;
};

import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { DesktopSideBarItemBadge } from './DesktopSideBarItemBadge';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';
import Home from 'dibs-icons/exports/Home';
import LightBulb from 'dibs-icons/exports/LightBulb';
import Listings from 'dibs-icons/exports/Listings';
import ShoppingBag from 'dibs-icons/exports/ShoppingBag';
import Message from 'dibs-icons/exports/Message';
import Analytics from 'dibs-icons/exports/Analytics';
import Campaign from 'dibs-icons/exports/Campaign';
import Finances from 'dibs-icons/exports/Finances';
import Production from 'dibs-icons/exports/Production';

import classnames from 'classnames';
import dibsCss from 'dibs-css';
import styles from './styles/DesktopSideBarItem.scss';

import { type DesktopSideBarItem_seller$key } from './__generated__/DesktopSideBarItem_seller.graphql';
import { type DesktopSideBarItem_navItem$key } from './__generated__/DesktopSideBarItem_navItem.graphql';
import { type DesktopSideBarItem_notifications$key } from './__generated__/DesktopSideBarItem_notifications.graphql';

type Props = {
    seller: DesktopSideBarItem_seller$key;
    navItem: DesktopSideBarItem_navItem$key;
    notifications?: DesktopSideBarItem_notifications$key | null;
    isActive?: boolean;
};

const sellerFragment = graphql`
    fragment DesktopSideBarItem_seller on Seller {
        ...DesktopSideBarItemBadge_seller
    }
`;

const navItemFragment = graphql`
    fragment DesktopSideBarItem_navItem on NavBarElement {
        type
        dataTn
        title
    }
`;

const notificationsFragment = graphql`
    fragment DesktopSideBarItem_notifications on DealerNavBarBadges {
        ...DesktopSideBarItemBadge_notifications
    }
`;

const NAV_ITEM_ICONS = {
    Home: Home,
    Recommendations: LightBulb,
    Listings: Listings,
    Orders: ShoppingBag,
    Messages: Message,
    Analytics: Analytics,
    Advertising: Campaign,
    Finance: Finances,
    Settings: Production,
} as const;

export const DesktopSideBarItem: FC<Props> = ({
    seller: sellerRef,
    navItem: navItemRef,
    notifications: notificationsRef,
    isActive,
}) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const navItem = useFragment(navItemFragment, navItemRef);
    const notifications = useFragment(notificationsFragment, notificationsRef);
    const { type, dataTn, title } = navItem || {};

    if (!title) {
        return null;
    }

    const Icon = hasKey(NAV_ITEM_ICONS, title) ? NAV_ITEM_ICONS[title] : null;

    return (
        <div
            data-tn={dataTn}
            className={classnames(styles.item, dibsCss.flex, dibsCss.pXsmall, {
                [dibsCss.bgMoonstone]: isActive,
            })}
        >
            <div
                className={classnames(styles.icon, {
                    [dibsCss.fillSatan]: !isActive,
                    [dibsCss.fillNoir]: isActive,
                })}
            >
                {Icon ? <Icon variation="20" /> : <div className={dibsCss.w20px} />}
            </div>
            <span
                className={classnames(
                    dibsCss.sassyFontBodyMediumHeavy,
                    dibsCss.overflowHidden,
                    dibsCss.textEllipsis,
                    {
                        [dibsCss.textSatan]: !isActive,
                        [dibsCss.textNoir]: isActive,
                    }
                )}
            >
                {title}
            </span>
            {type && (
                <DesktopSideBarItemBadge
                    type={type}
                    notifications={notifications}
                    seller={seller}
                />
            )}
        </div>
    );
};

/**
 * @generated SignedSource<<9891d57aa9ecb0fb1bfdda0577df2ac3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarExpandingAreaItems_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItem_seller" | "DesktopSideBarItem_seller">;
  readonly " $fragmentType": "DesktopSideBarExpandingAreaItems_seller";
};
export type DesktopSideBarExpandingAreaItems_seller$key = {
  readonly " $data"?: DesktopSideBarExpandingAreaItems_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItems_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarExpandingAreaItems_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItem_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarExpandingAreaItem_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "39e5ff8a698c7b90d15ad17fdd433862";

export default node;

import {
    useContext,
    useState,
    type FC,
    type ReactNode,
    Suspense,
    type Dispatch,
    type SetStateAction,
} from 'react';
import classnames from 'classnames';

import { FormattedMessage } from 'dibs-react-intl';
import GetHelpModalSellerLazy from 'dibs-contact-1stdibs/exports/GetHelpModalSellerLazy';
import { Link } from 'dibs-elements/exports/Link';
import getSellerSupportLink from 'dibs-support-link/exports/getSellerSupportLink';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
    triggerConstants,
} from 'dibs-tracking';

import { UserContext } from '../UserContext';
import { useCanShowLiveChat } from '../helpers/useCanShowLiveChat';

import styles from './styles/CustomHeaderMessage.scss';

const NeedAssistance: FC<{ setIsModalOpen: Dispatch<SetStateAction<boolean>> }> = ({
    setIsModalOpen,
}) => {
    const canShowLiveChat = useCanShowLiveChat();

    if (canShowLiveChat) {
        return null;
    }

    const pathname = window.location.pathname;
    const isSellerOnboardingPage = pathname.includes('/dealers/account-setup/');

    return (
        <>
            <span className={styles.prompt}>
                <FormattedMessage
                    id="dealerHeader.needAssistance"
                    defaultMessage="Need Assistance?"
                />
            </span>
            <div className={styles.linkContainer}>
                <Link
                    className={styles.supportLink}
                    href={getSellerSupportLink('')}
                    onClick={() => {
                        if (isSellerOnboardingPage) {
                            trackEvent({
                                eventName: eventNameConstants.EVENT_CONTACT_1STDIBS,
                                interaction_type: interactionTypeConstants.INTERACTION_TYPE_SUPPORT,
                                step_interaction_name:
                                    stepInteractionConstants.STEP_INTERACTION_SUPPORT_CENTER_CLICK,
                                trigger: triggerConstants.TRIGGER_SELLER_ONBOARDING,
                            });
                        }
                    }}
                >
                    <FormattedMessage
                        id="dealerHeader.supportLink"
                        defaultMessage="Support Center"
                    />
                </Link>
                <a
                    className={styles.supportLink}
                    onClick={() => {
                        setIsModalOpen(true);
                        if (isSellerOnboardingPage) {
                            trackEvent({
                                eventName: eventNameConstants.EVENT_CONTACT_1STDIBS,
                                interaction_type: interactionTypeConstants.INTERACTION_TYPE_SUPPORT,
                                step_interaction_name:
                                    stepInteractionConstants.STEP_INTERACTION_CONTACT_SUPPORT_CLICK,
                                trigger: triggerConstants.TRIGGER_SELLER_ONBOARDING,
                            });
                        }
                    }}
                >
                    <FormattedMessage
                        id="dealerHeader.contactLink"
                        defaultMessage="Contact Support"
                    />
                </a>
            </div>
        </>
    );
};

type Props = {
    children: ReactNode;
};

export const CustomHeaderMessage: FC<Props> = ({ children }) => {
    const { userOrSeller } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <Suspense fallback={null}>
            <GetHelpModalSellerLazy
                placement="dealerGlobalNav"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                topicValue="accountSettings"
            />
            <div
                className={classnames(styles.customHeaderMessage, {
                    [styles.separators]: userOrSeller,
                })}
            >
                <h1 data-tn="customBannerText" className={styles.bannerText}>
                    {children}
                </h1>
                <div className={styles.assistance}>
                    <Suspense fallback={null}>
                        <NeedAssistance setIsModalOpen={setIsModalOpen} />
                    </Suspense>
                </div>
            </div>
        </Suspense>
    );
};

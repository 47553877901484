/**
 * @generated SignedSource<<7cf35e85fc50f4eec03ce628d602ee7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarItemBadge_notifications$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useNotificationsConfig_notifications">;
  readonly " $fragmentType": "DesktopSideBarItemBadge_notifications";
};
export type DesktopSideBarItemBadge_notifications$key = {
  readonly " $data"?: DesktopSideBarItemBadge_notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItemBadge_notifications">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarItemBadge_notifications",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useNotificationsConfig_notifications"
    }
  ],
  "type": "DealerNavBarBadges",
  "abstractKey": null
};

(node as any).hash = "7a11181fcaec77fa3914e5c976326bd3";

export default node;

/**
 * @generated SignedSource<<a52aa714a04e38baa6a52982a70cac82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarNav_viewer$data = {
  readonly dealerNavBar: {
    readonly buttons: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarCreateListing_buttons">;
    } | null> | null;
    readonly links: {
      readonly desktopSideNav: ReadonlyArray<{
        readonly href: string | null;
        readonly items?: ReadonlyArray<{
          readonly href: string | null;
        } | null> | null;
      } | null> | null;
      readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItems_links" | "DesktopSideBarNavHeader_links" | "DesktopSideBarNavHelp_links">;
    } | null;
    readonly notifications?: {
      readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItems_notifications">;
    } | null;
  } | null;
  readonly seller?: {
    readonly serviceId: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarCreateListing_seller" | "DesktopSideBarItems_seller" | "DesktopSideBarNavHelp_seller" | "DesktopSideBarProfile_seller">;
  } | null;
  readonly " $fragmentType": "DesktopSideBarNav_viewer";
};
export type DesktopSideBarNav_viewer$key = {
  readonly " $data"?: DesktopSideBarNav_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarNav_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "href",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "customBannerText"
    },
    {
      "kind": "RootArgument",
      "name": "dotComHost"
    },
    {
      "kind": "RootArgument",
      "name": "hasSellerPk"
    },
    {
      "kind": "RootArgument",
      "name": "hyperwalletLink"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "renderNotifications"
    },
    {
      "kind": "RootArgument",
      "name": "sellerPk"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./DesktopSideBarNavRefetchQuery.graphql')
    }
  },
  "name": "DesktopSideBarNav_viewer",
  "selections": [
    {
      "condition": "hasSellerPk",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "sellerId",
              "variableName": "sellerPk"
            }
          ],
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarNavHelp_seller"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarCreateListing_seller"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarItems_seller"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarProfile_seller"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "customBannerText",
          "variableName": "customBannerText"
        },
        {
          "kind": "Variable",
          "name": "dotComHost",
          "variableName": "dotComHost"
        },
        {
          "kind": "Variable",
          "name": "hyperwalletLink",
          "variableName": "hyperwalletLink"
        },
        {
          "kind": "Variable",
          "name": "sellerPk",
          "variableName": "sellerPk"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "DealerNavBar",
      "kind": "LinkedField",
      "name": "dealerNavBar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NavBarButton",
          "kind": "LinkedField",
          "name": "buttons",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarCreateListing_buttons"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NavBarLinks",
          "kind": "LinkedField",
          "name": "links",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarNavHeader_links"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarNavHelp_links"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DesktopSideBarItems_links"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "desktopSideNav",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NavBarListItem",
                      "kind": "LinkedField",
                      "name": "items",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "NavBarList",
                  "abstractKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "renderNotifications",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "notifications",
              "args": null,
              "concreteType": "DealerNavBarBadges",
              "kind": "LinkedField",
              "name": "badges",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DesktopSideBarItems_notifications"
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "17cb5edf14840054b0ba1d7c870a251a";

export default node;

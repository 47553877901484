/**
 * @generated SignedSource<<a54ae07f448fd40a6bf467936ec1f6a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarItems_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItems_seller" | "DesktopSideBarItem_seller">;
  readonly " $fragmentType": "DesktopSideBarItems_seller";
};
export type DesktopSideBarItems_seller$key = {
  readonly " $data"?: DesktopSideBarItems_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItems_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarItems_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItem_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarExpandingAreaItems_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "1f89f49487cca8d042f179c9172663c4";

export default node;

import { type FC, type MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import serverVars from 'server-vars';
import { Link } from 'dibs-elements/exports/Link';
import { trackNavInteraction } from '../../helpers/tracking';
import GetHelpModalSellerLazy from 'dibs-contact-1stdibs/exports/GetHelpModalSellerLazy';
import {
    use1stdibsContactModalOpenState,
    useSendFeedbackModalOpenState,
} from '../NavItems/helpers';
import { SendFeedbackModal } from '../NavItems/SendFeedbackModal';
import { SERVER_VARS } from '../../../../server/constants';

import styles from './styles/DesktopSideBarNavSupportItem.scss';
import classNames from 'classnames';
import dibsCss from 'dibs-css';

import { type DesktopSideBarNavSupportItem_item$key } from './__generated__/DesktopSideBarNavSupportItem_item.graphql';

interface Props {
    item: DesktopSideBarNavSupportItem_item$key;
}

const itemFragment = graphql`
    fragment DesktopSideBarNavSupportItem_item on NavBarListItem {
        href
        type
        title
        eventName
        interactionType
    }
`;

export const DesktopSideBarNavSupportItem: FC<Props> = ({ item: itemRef }) => {
    const item = useFragment(itemFragment, itemRef);

    const { type, href, eventName, interactionType, title } = item || {};

    const { isContactModalOpen, setIsContactModalOpen, contactModalOpenHandler } =
        use1stdibsContactModalOpenState(href || '');
    const { isSendFeedbackModalOpen, setIsSendFeedbackModalOpen, sendFeedbackModalOpenHandler } =
        useSendFeedbackModalOpenState();

    if (!title) {
        return null;
    }

    const contact1stdibs = type === 'contact1stdibs';
    const sendFeedback = type === 'sendFeedback';

    let handler: (e: MouseEvent<HTMLAnchorElement>) => void | undefined;
    if (contact1stdibs) {
        handler = contactModalOpenHandler;
    } else if (sendFeedback) {
        handler = sendFeedbackModalOpenHandler;
    }

    const feedBackSurveyUrl = serverVars.get(SERVER_VARS.constants)?.feedBackSurveyUrl;

    return (
        <>
            <Link
                href={href || ''}
                onClick={e => {
                    if (handler) {
                        handler(e as MouseEvent<HTMLAnchorElement>);
                    } else {
                        trackNavInteraction({
                            action: eventName,
                            label: eventName,
                            interactionType: interactionType,
                        });
                    }
                }}
            >
                <span className={classNames(styles.supportLink, dibsCss.textGray500)}>{title}</span>
            </Link>
            {!!contact1stdibs && (
                <GetHelpModalSellerLazy
                    isOpen={isContactModalOpen}
                    onClose={() => setIsContactModalOpen(false)}
                    placement="dealerGlobalNav"
                />
            )}
            {!!sendFeedback && (
                <SendFeedbackModal
                    isOpen={isSendFeedbackModalOpen}
                    onClose={() => setIsSendFeedbackModalOpen(false)}
                    feedBackSurveyUrl={feedBackSurveyUrl}
                />
            )}
        </>
    );
};

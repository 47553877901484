import { useEffect, useRef } from 'react';
import { graphql, readInlineData, type Environment } from 'react-relay/legacy';
import presenceMutation from '../mutations/presenceMutation';
import { isMasquerading } from 'dibs-cookie-jar';
import throttle from 'lodash.throttle';
import { usePageVisibility } from 'dibs-elements/exports/usePageVisibility';
import { type useSendPresenceHeartbeats_seller$key } from './__generated__/useSendPresenceHeartbeats_seller.graphql';
import { type useSendPresenceHeartbeats_viewer$key } from './__generated__/useSendPresenceHeartbeats_viewer.graphql';
import { SEND_HEARTBEAT_INTERVAL, SEND_HEARTBEATS_IN_BACKGROUND, LISTENERS } from '../constants';

type Listener = () => void;

const handleEventListeners = (isAdd: boolean, eventListener: Listener): void => {
    const handlerFunction = isAdd
        ? (type: string) => window.addEventListener(type, eventListener)
        : (type: string) => window.removeEventListener(type, eventListener);

    LISTENERS.forEach(handlerFunction);
};

const useSendPresenceHeartbeatsViewer = graphql`
    fragment useSendPresenceHeartbeats_viewer on Viewer @inline {
        presenceFeatureFlag: featureFlag(feature: "presence")
    }
`;

const useSendPresenceHeartbeatsSeller = graphql`
    fragment useSendPresenceHeartbeats_seller on Seller @inline {
        sellerPreferences {
            showOnlineStatus
        }
    }
`;

type Props = {
    environment: Environment;
    viewer: useSendPresenceHeartbeats_viewer$key | null | undefined;
    seller: useSendPresenceHeartbeats_seller$key | null | undefined;
    isInternalUser: boolean;
};

export const useSendPresenceHeartbeats = ({
    environment,
    viewer,
    seller,
    isInternalUser,
}: Props): void => {
    let isEnabled = false;
    let viewerData;
    const heartbeatInterval = useRef<NodeJS.Timeout>();
    const heartbeatTimeout = useRef<NodeJS.Timeout>();
    if (viewer && !isInternalUser) {
        viewerData = readInlineData(useSendPresenceHeartbeatsViewer, viewer);
    }

    // check Feature Flag
    if (viewerData?.presenceFeatureFlag && !isInternalUser) {
        let sellerData;

        if (seller) {
            sellerData = readInlineData(useSendPresenceHeartbeatsSeller, seller);
            // check seller eligibility
            isEnabled = !!sellerData?.sellerPreferences?.showOnlineStatus;
        }
    }

    const isPageVisible = usePageVisibility();

    // send heartbeats if page is hidden
    useEffect(() => {
        const isMasked = isMasquerading(document.cookie);
        if (!isEnabled || isMasked) {
            return void 0;
        }
        const clearTimeoutAndInterval = (): void => {
            if (heartbeatInterval.current) {
                clearInterval(heartbeatInterval.current);
            }
            if (heartbeatTimeout.current) {
                clearInterval(heartbeatTimeout.current);
            }
        };

        if (isPageVisible) {
            clearTimeoutAndInterval();
        }

        // set an interval to keep sending heartbeats every SEND_HEARTBEAT_INTERVAL milliseconds
        // until SEND_HEARTBEATS_IN_BACKGROUND milliseconds passes
        if (!isPageVisible) {
            heartbeatInterval.current = setInterval(() => {
                presenceMutation(environment);
            }, SEND_HEARTBEAT_INTERVAL);
            // stop sending heartbeats after SEND_HEARTBEATS_IN_BACKGROUND expires
            heartbeatTimeout.current = setTimeout(
                clearTimeoutAndInterval,
                SEND_HEARTBEATS_IN_BACKGROUND
            );
        }

        return () => clearTimeoutAndInterval();
    }, [environment, isEnabled, isPageVisible]);

    // Send heartbeat when activity is detected
    useEffect(() => {
        const isMasked = isMasquerading(document.cookie);
        if (!isEnabled || isMasked) {
            return void 0;
        }

        const updatePresence = throttle(
            (): void => {
                presenceMutation(environment);
            },
            SEND_HEARTBEAT_INTERVAL,
            { trailing: true }
        );
        handleEventListeners(true, updatePresence);

        return () => {
            handleEventListeners(false, updatePresence);
        };
    }, [environment, isEnabled, isPageVisible]);
};

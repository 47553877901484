/**
 * @generated SignedSource<<50a4e40ccaccaab782d9d7cf6b55dc84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarNavHelp_links$data = {
  readonly desktopSideNav: ReadonlyArray<{
    readonly dataTn: string | null;
    readonly eventName: string | null;
    readonly items?: ReadonlyArray<{
      readonly href: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarNavSupportItem_item">;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "DesktopSideBarNavHelp_links";
};
export type DesktopSideBarNavHelp_links$key = {
  readonly " $data"?: DesktopSideBarNavHelp_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarNavHelp_links">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarNavHelp_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "desktopSideNav",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataTn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eventName",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NavBarListItem",
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "href",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DesktopSideBarNavSupportItem_item"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "NavBarList",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarLinks",
  "abstractKey": null
};

(node as any).hash = "3aea6cdffe914b1555f2fe37ac7f246b";

export default node;

/**
 * @generated SignedSource<<951c651415f69b7ae75a056686a4e321>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavDropdownItem_navItem$data = {
  readonly badgeText: string | null;
  readonly dataTn: string | null;
  readonly eventName: string | null;
  readonly href: string | null;
  readonly newWindow: boolean | null;
  readonly title: string | null;
  readonly type: string | null;
  readonly " $fragmentType": "NavDropdownItem_navItem";
};
export type NavDropdownItem_navItem$key = {
  readonly " $data"?: NavDropdownItem_navItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavDropdownItem_navItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavDropdownItem_navItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataTn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newWindow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badgeText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventName",
      "storageKey": null
    }
  ],
  "type": "NavBarElement",
  "abstractKey": "__isNavBarElement"
};

(node as any).hash = "600c25580b3f7be7771991d32a570d74";

export default node;

import { useContext, type FC, type ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { interactionTypeConstants } from 'dibs-tracking';
import classnames from 'classnames';

import { UserContext } from '../../UserContext';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import useSellerNotifications from '../../hooks/useSellerNotifications';
import NavBarButtons from '../NavItems/NavBarButtons';
import UserMenuToggle from '../UserMenuToggle';
import NavItem from '../NavItems/NavItem';
import DalAccountNav from './DalAccountNav';
import { TRACK_ACTIONS } from '../../helpers/tracking';

import styles from './styles/Nav.scss';
import utilStyles from '../styles/utils.scss';
import navItemStyles from '../NavItems/styles/NavItem.scss';

import { type DesktopNav_seller$data } from './__generated__/DesktopNav_seller.graphql';
import { type DesktopNav_buttons$data } from './__generated__/DesktopNav_buttons.graphql';
import { type DesktopNav_internalLinks$data } from './__generated__/DesktopNav_internalLinks.graphql';
import { type DesktopNav_links$data } from './__generated__/DesktopNav_links.graphql';
import { type DesktopNav_notifications$data } from './__generated__/DesktopNav_notifications.graphql';

type Props = {
    seller: DesktopNav_seller$data | null | undefined;
    buttons: DesktopNav_buttons$data | null | undefined;
    internalLinks: DesktopNav_internalLinks$data | null | undefined;
    links: DesktopNav_links$data | null | undefined;
    notifications: DesktopNav_notifications$data | null | undefined;
    customHeader: ReactNode | null;
};

export const DesktopNav: FC<Props> = ({
    links,
    internalLinks,
    buttons,
    notifications,
    customHeader,
    seller,
}) => {
    const { isDealer, userOrSeller } = useContext(UserContext);
    useSellerNotifications(seller);

    let desktopNav;
    let accountNav;
    if (isDealer) {
        const { desktopNav: desktopNavLinks } = links || {};
        ({ accountNav } = links || {});
        desktopNav = desktopNavLinks || [];
    } else {
        ({ desktopNav } = internalLinks || {});
    }

    return (
        <ul className={styles.navBar}>
            {!customHeader &&
                (desktopNav || []).map(
                    (navItem, i) =>
                        !!navItem && (
                            <NavItem
                                isMobile={false}
                                seller={seller}
                                key={`desktopNav-${i}`}
                                notifications={notifications}
                                navItem={navItem}
                            />
                        )
                )}
            {customHeader}
            {/* TODO: can this be a pseudo element? or something other than a list item? */}
            {!customHeader && <li className={utilStyles.flexSpacer} />}
            {isDealer && !customHeader && (
                <NavBarButtons isMobile={false} buttons={buttons} seller={seller} />
            )}
            {userOrSeller ? (
                <NavItem
                    isMobile={false}
                    className={classnames(styles.userMenu, navItemStyles.userMenu)}
                    notifications={notifications}
                    seller={seller}
                    navItem={null}
                    customNavItem={{
                        dataTn: 'top-navbar-user-menu',
                        title: <UserMenuToggle />,
                        eventName: TRACK_ACTIONS.MY_ACCOUNT,
                        interactionType: interactionTypeConstants.INTERACTION_TYPE_MY_ACCOUNT,
                    }}
                    renderAsDiv // to avoid nested <button>/<a> react warnings
                >
                    <DalAccountNav
                        isMobile={false}
                        accountNav={(accountNav || []).filter(filterNulls)}
                        seller={seller}
                    />
                </NavItem>
            ) : null}
        </ul>
    );
};

export default createFragmentContainer(DesktopNav, {
    seller: graphql`
        fragment DesktopNav_seller on Seller {
            ...NavItem_seller
            ...NavBarButtons_seller
            ...DalAccountNav_seller
            ...useSellerNotifications_seller
        }
    `,
    buttons: graphql`
        fragment DesktopNav_buttons on NavBarButton @relay(plural: true) {
            ...NavBarButtons_buttons
        }
    `,
    internalLinks: graphql`
        fragment DesktopNav_internalLinks on NavBarLinks {
            desktopNav {
                ...NavItem_navItem
            }
        }
    `,
    links: graphql`
        fragment DesktopNav_links on NavBarLinks {
            desktopNav {
                ...NavItem_navItem
            }
            accountNav {
                ...DalAccountNav_accountNav
            }
        }
    `,
    notifications: graphql`
        fragment DesktopNav_notifications on DealerNavBarBadges {
            ...NavItem_notifications
        }
    `,
});

/**
 * @generated SignedSource<<192af568ea94495e3819feb56bb0fa18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarItem_navItem$data = {
  readonly dataTn: string | null;
  readonly title: string | null;
  readonly type: string | null;
  readonly " $fragmentType": "DesktopSideBarItem_navItem";
};
export type DesktopSideBarItem_navItem$key = {
  readonly " $data"?: DesktopSideBarItem_navItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItem_navItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarItem_navItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataTn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "NavBarElement",
  "abstractKey": "__isNavBarElement"
};

(node as any).hash = "c08371d6ef467e4dbfec02149f1fdbcf";

export default node;

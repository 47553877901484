import { type FC, useEffect, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { datadogRum } from '@datadog/browser-rum';
import { Badge } from 'dibs-elements/exports/Badge';
import Dot from 'dibs-icons/exports/Dot';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';
import { toDataTn } from '../NavItems/helpers';
import { useNotificationsConfig } from '../../helpers/useNotificationsConfig';

import styles from './styles/DesktopSideBarItemBadge.scss';
import classnames from 'classnames';
import dibsCss from 'dibs-css';

import { type DesktopSideBarItemBadge_seller$key } from './__generated__/DesktopSideBarItemBadge_seller.graphql';
import { type DesktopSideBarItemBadge_notifications$key } from './__generated__/DesktopSideBarItemBadge_notifications.graphql';

type Props = {
    type: string;
    seller: DesktopSideBarItemBadge_seller$key;
    notifications?: DesktopSideBarItemBadge_notifications$key | null;
};

const sellerFragment = graphql`
    fragment DesktopSideBarItemBadge_seller on Seller {
        ...useNotificationsConfig_seller
    }
`;

const notificationsFragment = graphql`
    fragment DesktopSideBarItemBadge_notifications on DealerNavBarBadges {
        ...useNotificationsConfig_notifications
    }
`;

export const DesktopSideBarItemBadge: FC<Props> = ({
    type,
    seller: sellerRef,
    notifications: notificationsRef,
}) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const notifications = useFragment(notificationsFragment, notificationsRef);

    const notificationsConfig = useNotificationsConfig({ seller, notifications });

    const config = useMemo(
        () =>
            hasKey(notificationsConfig, type)
                ? notificationsConfig[type]
                : { count: '0', dataTn: '', showCount: false },
        [notificationsConfig, type]
    );
    const dataTn = `navbar-badge-${toDataTn(config?.dataTn)}`;

    const [count, isCeil] = config?.count || ['0', false];
    const showBadge = Number(count) > 0 || isCeil;
    const showCount = config?.showCount;

    useEffect(() => {
        if (dataTn && config && type) {
            datadogRum.addAction(`${dataTn}-Badge-Displayed`);
            datadogRum.addAction(`${type}-Notification-Displayed`, {
                navBarNotificationDisplayed: showBadge,
                navBarNotificationConfig: config,
            });
        }
    }, [dataTn, showBadge, config, type]);

    if (!showBadge) {
        return null;
    }

    return (
        <div
            data-tn={dataTn}
            className={classnames(dibsCss.mlXsmall, {
                [styles.badge]: !!showCount,
                [styles.dot]: !showCount,
                [dibsCss.fillAlertprimary]: !showCount,
            })}
        >
            {showCount ? <Badge type="alert">{count}</Badge> : <Dot variation="20" />}
        </div>
    );
};

import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type BoxProps = {
    className?: string;
};

const Box: FunctionComponent<BoxProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'box'}
            viewBox="0 0 250 250"
        >
            <path d="M126.7 1.5L3.3 41.4 13.7 187l112.5 61.5 113.1-60.6 10-145.1L126.7 1.5zM122.2 105v132.1L21.4 182 12.1 51l110.1 54zM18.3 45.1L126.7 9.9l107.9 36.4-108.7 51.5L18.3 45.1zm213.4 137.7l-101.5 54.4V104.7l110.4-52.3-8.9 130.4z" />
        </svg>
    );
};
export default Box;

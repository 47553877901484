import { type FC, useEffect, useMemo } from 'react';
import { useRefetchableFragment, graphql } from 'react-relay';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

import { DesktopSideBarNavHeader } from './DesktopSideBarNavHeader';
import { DesktopSideBarNavHelp } from './DesktopSideBarNavHelp';
import { DesktopSideBarLogo } from './DesktopSideBarLogo';
import { DesktopSideBarCreateListing } from './DesktopSideBarCreateListing';
import { DesktopSideBarItems } from './DesktopSideBarItems';
import { DesktopSideBarProfile } from './DesktopSideBarProfile';

import classnames from 'classnames';
import dibsCss from 'dibs-css';
import styles from './styles/DesktopSideBarNav.scss';

import { type DesktopSideBarNavRefetchQuery } from './__generated__/DesktopSideBarNavRefetchQuery.graphql';
import { type DesktopSideBarNav_viewer$key } from './__generated__/DesktopSideBarNav_viewer.graphql';

const browserUrl = window.location.pathname;

type Props = {
    viewer?: DesktopSideBarNav_viewer$key;
};

const viewerFragment = graphql`
    fragment DesktopSideBarNav_viewer on Viewer
    @argumentDefinitions(renderNotifications: { type: "Boolean", defaultValue: false })
    @refetchable(queryName: "DesktopSideBarNavRefetchQuery") {
        seller(sellerId: $sellerPk) @include(if: $hasSellerPk) {
            serviceId
            ...DesktopSideBarNavHelp_seller
            ...DesktopSideBarCreateListing_seller
            ...DesktopSideBarItems_seller
            ...DesktopSideBarProfile_seller
        }
        dealerNavBar(
            sellerPk: $sellerPk
            dotComHost: $dotComHost
            hyperwalletLink: $hyperwalletLink
            customBannerText: $customBannerText
            userId: $userId
        ) {
            buttons {
                ...DesktopSideBarCreateListing_buttons
            }
            links {
                ...DesktopSideBarNavHeader_links
                ...DesktopSideBarNavHelp_links
                ...DesktopSideBarItems_links
                desktopSideNav {
                    ... on NavBarList {
                        items {
                            href
                        }
                    }
                    href
                }
            }
            notifications: badges @include(if: $renderNotifications) {
                ...DesktopSideBarItems_notifications
            }
        }
    }
`;

export const DesktopSideBarNav: FC<Props> = ({ viewer: viewerRef }) => {
    const [viewer, refetch] = useRefetchableFragment<
        DesktopSideBarNavRefetchQuery,
        DesktopSideBarNav_viewer$key
    >(viewerFragment, viewerRef);
    const seller = viewer?.seller || null;
    const { serviceId: hasSellerPk } = seller || {};

    useEffect(() => {
        if (hasSellerPk) {
            refetch({ renderNotifications: true });
        }
    }, [refetch, hasSellerPk]);

    const dealerNavBar = viewer?.dealerNavBar;
    const buttons = dealerNavBar?.buttons;
    const links = dealerNavBar?.links;
    const notifications = dealerNavBar?.notifications;

    /**
     * Order nav links by length, so we can find the most precise length
     * for the current page then pass down to where we need it
     */
    const activeLink = useMemo(() => {
        const sideNav = viewer?.dealerNavBar?.links?.desktopSideNav || [];
        const orderedNavLinks = [];
        for (let i = 0; i < sideNav.length; i++) {
            const currentNav = sideNav[i];
            if (currentNav?.items?.length) {
                orderedNavLinks.push(...currentNav.items.map(l => l?.href));
            } else {
                orderedNavLinks.push(currentNav?.href);
            }
        }
        orderedNavLinks.sort((a, b) => (b?.length || 0) - (a?.length || 0));
        return orderedNavLinks.find(nl => nl && browserUrl.startsWith(nl)) || '';
    }, [viewer?.dealerNavBar?.links?.desktopSideNav]);

    if (!seller || !dealerNavBar || !buttons || !links) {
        return null;
    }

    const truthyButtons = buttons.filter(filterFalsy);

    return (
        <>
            <DesktopSideBarNavHeader links={links} activeLink={activeLink} />
            <DesktopSideBarNavHelp seller={seller} links={links} />
            <nav
                className={classnames(
                    dibsCss.absolute,
                    dibsCss.top0,
                    dibsCss.left0,
                    dibsCss.bgWhite,
                    dibsCss.flex,
                    dibsCss.flexCol,
                    styles.sideBar
                )}
            >
                <DesktopSideBarLogo />
                <DesktopSideBarCreateListing seller={seller} buttons={truthyButtons} />
                <DesktopSideBarItems
                    seller={seller}
                    links={links}
                    notifications={notifications}
                    activeLink={activeLink}
                />
                <DesktopSideBarProfile seller={seller} />
            </nav>
        </>
    );
};

/**
 * @generated SignedSource<<25b60f0b5fb8ba2c04b73bbe00720d2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DalMobileNav_notifications$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NavItem_notifications">;
  readonly " $fragmentType": "DalMobileNav_notifications";
};
export type DalMobileNav_notifications$key = {
  readonly " $data"?: DalMobileNav_notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"DalMobileNav_notifications">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DalMobileNav_notifications",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavItem_notifications"
    }
  ],
  "type": "DealerNavBarBadges",
  "abstractKey": null
};

(node as any).hash = "a4a59079ac723e2b813bf427eb8e246d";

export default node;

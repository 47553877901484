/**
 * @generated SignedSource<<527b8fe70652203d3a4b635a37ee67e8>>
 * @relayHash f53d8d8fd3bd422c9f018025c267d8d8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/504.0.0-2025-04-01T10:32:06.638Z/DealerWrapperRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DealerWrapperRefetchQuery$variables = {
  customBannerText?: string | null;
  dotComHost: string;
  hasSellerPk: boolean;
  hyperwalletLink: string;
  renderNotifications: boolean;
  sellerPk?: string | null;
  userId?: string | null;
};
export type DealerWrapperRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DealerWrapper_viewer">;
  };
};
export type DealerWrapperRefetchQuery = {
  response: DealerWrapperRefetchQuery$data;
  variables: DealerWrapperRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "customBannerText"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dotComHost"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSellerPk"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hyperwalletLink"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "renderNotifications"
},
v5 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "sellerPk"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v7 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "href",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interactionType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isNavBarElement"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataTn",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newWindow",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exactLocation",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "badgeText",
  "storageKey": null
},
v21 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NavBarListItem",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v9/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v20/*: any*/)
          ],
          "type": "NavBarElement",
          "abstractKey": "__isNavBarElement"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarList",
  "abstractKey": null
},
v22 = [
  (v13/*: any*/),
  (v14/*: any*/),
  (v9/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v21/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DealerWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DealerWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DealerWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "navBar",
            "args": [
              {
                "kind": "Variable",
                "name": "customBannerText",
                "variableName": "customBannerText"
              },
              {
                "kind": "Variable",
                "name": "dotComHost",
                "variableName": "dotComHost"
              },
              {
                "kind": "Variable",
                "name": "hyperwalletLink",
                "variableName": "hyperwalletLink"
              },
              {
                "kind": "Variable",
                "name": "sellerPk",
                "variableName": "sellerPk"
              },
              (v7/*: any*/)
            ],
            "concreteType": "DealerNavBar",
            "kind": "LinkedField",
            "name": "dealerNavBar",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NavBarButton",
                "kind": "LinkedField",
                "name": "buttons",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NavBarLinks",
                "kind": "LinkedField",
                "name": "links",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "mobileNav",
                    "plural": true,
                    "selections": (v22/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "accountNav",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v9/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "desktopNav",
                    "plural": true,
                    "selections": (v22/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "condition": "renderNotifications",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "notifications",
                    "args": null,
                    "concreteType": "DealerNavBarBadges",
                    "kind": "LinkedField",
                    "name": "badges",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "listings",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "orders",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "messages",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recommendations",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "condition": "hasSellerPk",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "sellerId",
                    "variableName": "sellerPk"
                  }
                ],
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RepData",
                    "kind": "LinkedField",
                    "name": "sellerRep",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VatConfiguration",
                    "kind": "LinkedField",
                    "name": "vatConfiguration",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priceBookName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vertical",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v7/*: any*/)
                    ],
                    "concreteType": "CreateListingOption",
                    "kind": "LinkedField",
                    "name": "createListingOptions",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PresenceResults",
                    "kind": "LinkedField",
                    "name": "presence",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recentOnlineDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerPreferences",
                    "kind": "LinkedField",
                    "name": "sellerPreferences",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "showOnlineStatus",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/504.0.0-2025-04-01T10:32:06.638Z/DealerWrapperRefetchQuery",
    "metadata": {},
    "name": "DealerWrapperRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e8022cdda6d0e34e7754d08b2bc84282";

export default node;

/**
 * @generated SignedSource<<cc55680986dbc38bcc414ea4abd9f4d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopNav_notifications$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NavItem_notifications">;
  readonly " $fragmentType": "DesktopNav_notifications";
};
export type DesktopNav_notifications$key = {
  readonly " $data"?: DesktopNav_notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopNav_notifications">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopNav_notifications",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavItem_notifications"
    }
  ],
  "type": "DealerNavBarBadges",
  "abstractKey": null
};

(node as any).hash = "94f584e5ed0979751bd854739cb22bdb";

export default node;

import { type FC, useState, useEffect, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';
import UserCircle from 'dibs-icons/exports/UserCircle';
import Mask from 'dibs-icons/exports/legacy/Mask';
import ArrowUp from 'dibs-icons/exports/legacy/ArrowUp';
import { Tooltip } from 'dibs-elements/exports/Tooltip';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { trackNavInteraction } from '../../helpers/tracking';
import { UserContext } from '../../UserContext';

import classnames from 'classnames';
import dibsCss from 'dibs-css';
import styles from './styles/DesktopSideBarProfile.scss';

import { type DesktopSideBarProfile_seller$key } from './__generated__/DesktopSideBarProfile_seller.graphql';

type Props = {
    seller: DesktopSideBarProfile_seller$key;
};

const sellerFragment = graphql`
    fragment DesktopSideBarProfile_seller on Seller {
        sellerProfile {
            company
        }
    }
`;

export const DesktopSideBarProfile: FC<Props> = ({ seller: sellerRef }) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const { masqueradeSeller } = useContext(UserContext);
    const sellerCompany = seller?.sellerProfile?.company;

    useEffect(() => {
        if (isMenuVisible) {
            trackNavInteraction({
                action: 'account dropdown',
                label: 'account',
                interactionType: 'drop down',
                isImpression: true,
            });
        }
    }, [isMenuVisible]);

    return (
        <>
            <div
                onClick={() => setIsMenuVisible(prev => !prev)}
                className={classnames(
                    dibsCss.relative,
                    dibsCss.flexGrow0,
                    dibsCss.basisXlarge,
                    dibsCss.flex,
                    dibsCss.justifyCenter,
                    dibsCss.itemsCenter,
                    dibsCss.gapXsmall,
                    dibsCss.pxSmall,
                    dibsCss.border0,
                    dibsCss.borderT,
                    dibsCss.borderSolid,
                    dibsCss.borderDolphin,
                    dibsCss.cursorPointer
                )}
            >
                <div className={dibsCss.fillNoir}>
                    {masqueradeSeller ? (
                        <Mask className={classnames(styles.maskIcon, dibsCss.alignMiddle)} />
                    ) : (
                        <UserCircle variation="20" />
                    )}
                </div>
                {sellerCompany && (
                    <div
                        className={classnames(
                            dibsCss.sassyFontBodySmallHeavy,
                            dibsCss.textNoir,
                            dibsCss.overflowHidden,
                            dibsCss.whitespaceNowrap,
                            dibsCss.textEllipsis
                        )}
                    >
                        {sellerCompany}
                    </div>
                )}
                <div className={classnames(dibsCss.h14px, dibsCss.w14px, styles.arrowUpIcon)}>
                    <ArrowUp className={dibsCss.fillNoir} />
                </div>
                <Tooltip
                    isVisible={isMenuVisible}
                    type="autoWidth"
                    direction="top"
                    paddingSize="none"
                    closeOnOutsideClick
                    onClose={() => setIsMenuVisible(false)}
                >
                    <div className={classnames(dibsCss.pSmall, styles.tooltip)}>
                        <Link
                            href="/logout/dealer"
                            dataTn="logout-link"
                            onClick={() =>
                                trackNavInteraction({
                                    action: 'logout',
                                    label: 'logout',
                                    interactionType: 'drop down',
                                })
                            }
                        >
                            <FormattedMessage
                                id="dal.header.userMenuOptions.logout"
                                defaultMessage="Logout"
                            />
                        </Link>
                    </div>
                </Tooltip>
            </div>
        </>
    );
};

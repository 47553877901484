import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Link } from 'dibs-elements/exports/Link';
import { DesktopSideBarItem } from './DesktopSideBarItem';
import { DesktopSideBarExpandingAreaItems } from './DesktopSideBarExpandingAreaItems';
import { trackNavInteraction } from '../../helpers/tracking';

import classnames from 'classnames';
import dibsCss from 'dibs-css';

import { type DesktopSideBarItems_seller$key } from './__generated__/DesktopSideBarItems_seller.graphql';
import { type DesktopSideBarItems_links$key } from './__generated__/DesktopSideBarItems_links.graphql';
import { type DesktopSideBarItems_notifications$key } from './__generated__/DesktopSideBarItems_notifications.graphql';

type Props = {
    activeLink: string;
    seller: DesktopSideBarItems_seller$key;
    links: DesktopSideBarItems_links$key;
    notifications?: DesktopSideBarItems_notifications$key | null;
};

const sellerFragment = graphql`
    fragment DesktopSideBarItems_seller on Seller {
        ...DesktopSideBarItem_seller
        ...DesktopSideBarExpandingAreaItems_seller
    }
`;

const linksFragment = graphql`
    fragment DesktopSideBarItems_links on NavBarLinks {
        desktopSideNav {
            ...DesktopSideBarItem_navItem
            ...DesktopSideBarExpandingAreaItems_navItem
            ... on NavBarList {
                items {
                    title
                }
            }
            title
            href
            dataTn
            eventName
            interactionType
        }
    }
`;

const notificationsFragment = graphql`
    fragment DesktopSideBarItems_notifications on DealerNavBarBadges {
        ...DesktopSideBarItem_notifications
        ...DesktopSideBarExpandingAreaItems_notifications
    }
`;

export const DesktopSideBarItems: FC<Props> = ({
    activeLink,
    seller: sellerRef,
    links: linksRef,
    notifications: notificationsRef,
}) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const links = useFragment(linksFragment, linksRef);
    const notifications = useFragment(notificationsFragment, notificationsRef);

    const { desktopSideNav } = links;

    if (!desktopSideNav?.length) {
        return null;
    }

    return (
        <div className={classnames(dibsCss.flex1, dibsCss.overflowYScroll)}>
            <div className={classnames(dibsCss.flex, dibsCss.flexCol, dibsCss.mXsmall)}>
                {desktopSideNav.map(navItem => {
                    if (!navItem || navItem.dataTn === 'support') {
                        return null;
                    }
                    const { items, title, href, eventName, interactionType } = navItem;

                    return items?.length ? (
                        <DesktopSideBarExpandingAreaItems
                            key={title}
                            seller={seller}
                            navItem={navItem}
                            notifications={notifications}
                            activeLink={activeLink}
                        />
                    ) : (
                        <Link
                            key={title}
                            href={href || ''}
                            onClick={() =>
                                trackNavInteraction({
                                    action: eventName,
                                    label: eventName,
                                    interactionType,
                                })
                            }
                        >
                            <DesktopSideBarItem
                                seller={seller}
                                navItem={navItem}
                                notifications={notifications}
                                isActive={activeLink === href}
                            />
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

/**
 * @generated SignedSource<<257e98167a686f72ba73665bff3b9307>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavBarButtons_seller$data = {
  readonly createListingOptions: ReadonlyArray<{
    readonly dataTn: string | null;
    readonly eventName: string | null;
    readonly text: string | null;
    readonly url: string | null;
  } | null> | null;
  readonly vertical: string | null;
  readonly " $fragmentType": "NavBarButtons_seller";
};
export type NavBarButtons_seller$key = {
  readonly " $data"?: NavBarButtons_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavBarButtons_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavBarButtons_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vertical",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "CreateListingOption",
      "kind": "LinkedField",
      "name": "createListingOptions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataTn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eventName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "15bed575722fdb07541243982d4f861d";

export default node;

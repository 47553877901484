/**
 * @generated SignedSource<<4cf9329fc3106dad997459a0f179fa42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarExpandingAreaItem_notifications$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItemVATReportLink_notifications" | "DesktopSideBarItem_notifications">;
  readonly " $fragmentType": "DesktopSideBarExpandingAreaItem_notifications";
};
export type DesktopSideBarExpandingAreaItem_notifications$key = {
  readonly " $data"?: DesktopSideBarExpandingAreaItem_notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItem_notifications">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarExpandingAreaItem_notifications",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItem_notifications"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItemVATReportLink_notifications"
    }
  ],
  "type": "DealerNavBarBadges",
  "abstractKey": null
};

(node as any).hash = "c2a946aba279ca8f184d05b7efbd333a";

export default node;

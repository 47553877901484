import { createRoot } from 'react-dom/client';
import { Suspense } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import serverVars from 'server-vars';

import { ErrorBoundary } from 'dibs-error-tools/ErrorBoundary';
import { IntlProvider } from 'dibs-react-intl';

import { getRelayEnvironment } from '../clientRelayEnvironment';
import { SupportChat } from '../components/SupportChat';

function initSupportChat(): void {
    const node = document.getElementById('js-support-chat');

    if (!node) {
        return;
    }

    const supportChatRoot = createRoot(node);

    supportChatRoot.render(
        <ErrorBoundary fallback={() => null}>
            <IntlProvider locale={serverVars.get('locale')} messages={serverVars.get('messages')}>
                <RelayEnvironmentProvider environment={getRelayEnvironment('seller')}>
                    <Suspense fallback={null}>
                        <SupportChat />
                    </Suspense>
                </RelayEnvironmentProvider>
            </IntlProvider>
        </ErrorBoundary>
    );
}

initSupportChat();

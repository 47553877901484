import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type LightbulbProps = {
    className?: string;
};

const Lightbulb: FunctionComponent<LightbulbProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'lightbulb'}
            viewBox="0 0 250 250"
        >
            <path d="M166.3 45.8c-14.1-9.9-27.9-12.1-29.2-12.3-6.3-1.1-11.1-1.1-13.1-1.1 0 0-8.1 0-14.2 1.1-1.3.2-15.1 2.5-28.9 12.4-13.2 9.3-28.9 27.6-29.2 61.3-.2 2.6-.9 22.8 18.4 48 .4.6 1.2 1.4 2.7 3 1.1 1.1 2.2 2.4 3.2 3.5 11.4 13.1 13.2 22.4 12.8 28v9c-.1 1.3-.1 7.1 3.8 11.3.5.6 1.2 1.1 2 1.7-.3 2-.3 5.6 1.7 8.9-.6 3-1.5 9.5 1.8 14 1.1 1.4 3 3.1 6.1 3.8.3 2.4 1 5.3 3 7.5 1.8 2 4.4 3.1 7.3 3.1h18.1c.8 0 4.1-.2 6.7-3 1.7-1.9 2.6-4.4 2.8-7.5 1.7-.4 4.1-1.3 6.1-3.6 2.7-3.1 3.6-7.6 2.8-13.4 1.3-1.8 2.6-4.7 1.9-9.2.8-.5 1.7-1.1 2.4-1.8 4.3-4.3 4.6-11.1 4.6-11.8V190l-.2-.4c-.9-10.9 7.3-22.2 12.3-28 1.9-2.2 4.6-5.5 5.4-6.5 19.3-25.2 18.6-45.4 18.4-48-.1-33.6-16.1-51.9-29.5-61.3zm-33.5 197h-18.2c-1.6 0-2.3-.5-2.7-.9-.7-.8-1.1-1.9-1.3-2.9h25.6c-.2 1-.5 2.2-1.1 2.9-1.1 1-2.4.9-2.3.9zm7.5-9.8h-32.8c-3.1 0-4.1-1.5-4.4-1.9-1.1-1.4-1.3-3.8-1.2-5.8 1.5.6 3.3.8 5.3.9l33.1-.2h.1c.5 0 2.6.1 4.9-.6 0 2.3-.5 4.4-1.5 5.6-1.4 1.6-3.4 2-3.5 2zm5.8-15.1c-1.5 1.8-4.7 2.1-5.8 2.1h-32.7c-2.8 0-4.8-1-5.8-2.5-.8-1.2-1.1-2.6-1.1-3.7.7.1 1.5.2 2.3.2h44.1c.1 1.6-.2 2.9-1 3.9zm39.2-98.1c-1.7 6.8-5.7 17.6-15.1 29.8-.6.7-3.3 3.7-5.3 6.1-7.2 8.4-15.9 21.1-14.9 34.6v8.6c0 .9-.2 4-1.5 5.4-.4.4-.7.8-1.9.8H103c-2.4 0-3.2-.9-3.5-1.2-1.3-1.3-1.4-3.9-1.4-4.6v-8.9c.8-10.5-4.3-22.2-15-34.6-1-1.2-2.3-2.6-3.4-3.7-.8-.9-1.9-2-2.1-2.4-9.4-12.2-13.4-22.9-15.1-29.8-1.9-7.4-1.5-12-1.5-12v-.4c.3-56.7 48-64.5 50-64.8h.1c3.7-.6 8.3-.9 10.7-.9h1.3c2 0 6.3 0 12.3 1h.1c2.1.3 50.9 8 51.2 64.7v.4c0-.1.4 4.5-1.4 11.9z" />
            <path d="M122.7 25c1.4 0 2.6-.9 2.6-2.4l-.1-20.1c0-1.4-1.2-2.5-2.6-2.5-1.4 0-2.6 1.1-2.6 2.5l.1 19.9c.1 1.4 1.2 2.6 2.6 2.6z" />
            <path d="M228.6 79.9c-.5-1.3-2-1.9-3.3-1.4L206 86.4c-1.3.5-1.9 2-1.4 3.3.4 1 1.4 1.6 2.4 1.6.3 0 .7-.1 1-.2l19.3-7.9c1.2-.5 1.8-2 1.3-3.3z" />
            <path d="M42.1 90.3c1 0 2-.6 2.4-1.6.5-1.3-.1-2.8-1.4-3.3l-19.5-7.7c-1.3-.5-2.8.1-3.3 1.4-.5 1.3.1 2.8 1.4 3.3l19.5 7.7c.2.2.6.2.9.2z" />
            <path d="M193.1 24.2l-14.8 15.6c-1 1-.9 2.7.1 3.6.5.5 1.1.7 1.8.7s1.4-.3 1.9-.8l14.8-15.6c1-1 .9-2.7-.1-3.6-1.1-1-2.7-1-3.7.1z" />
            <path d="M220.2 146.2l-18.7-8.5c-1.3-.6-2.8 0-3.4 1.3-.6 1.3 0 2.8 1.3 3.4l18.7 8.5c.3.2.7.2 1.1.2 1 0 1.9-.6 2.3-1.5.5-1.3 0-2.8-1.3-3.4z" />
            <path d="M46.4 138.6l-20.5 6.8c-1.3.4-2.1 1.9-1.6 3.2.4 1.1 1.4 1.8 2.4 1.8.3 0 .5 0 .8-.1l20.5-6.8c1.3-.4 2.1-1.9 1.6-3.2-.4-1.4-1.9-2.1-3.2-1.7z" />
            <path d="M67.6 43.7c.6 0 1.3-.2 1.8-.7 1-1 1.1-2.6.1-3.6L54.7 24.1c-1-1-2.6-1.1-3.6-.1s-1.1 2.6-.1 3.6l14.7 15.3c.5.6 1.2.8 1.9.8z" />
        </svg>
    );
};
export default Lightbulb;

import { type FunctionComponent } from 'react';
import * as React from 'react';

import styles from './main.scss';

type TooltipHeaderProps = {
    title: React.ReactNode;
};

export const TooltipHeader: FunctionComponent<TooltipHeaderProps> = props => {
    const { title } = props;
    return <div className={styles.title}>{title}</div>;
};

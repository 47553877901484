import { type FC } from 'react';
import LogoIcon from 'dibs-icons/exports/legacy/Logo2020';

type Props = {
    className?: string;
};

const Logo: FC<Props> = ({ className }) => {
    return <LogoIcon className={className} />;
};

export default Logo;

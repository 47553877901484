import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type HomeProps = {
    className?: string;
};

const Home: FunctionComponent<HomeProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'home'}
            viewBox="0 0 250 250"
        >
            <path d="M245.1 120.7l-122-114L.3 120l7.5 8.1 16.3-15V245h70v-73h57v73h70V113.2l16.6 15.5 7.4-8zM210 103v131h-48v-73H83v73H35V102.9l88-81.2 87 81.3z" />
        </svg>
    );
};
export default Home;

/**
 * @generated SignedSource<<c511dbe8f41b90106f3539d204103637>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavBarButtons_buttons$data = ReadonlyArray<{
  readonly eventName: string | null;
  readonly href: string | null;
  readonly id: string | null;
  readonly interactionType: string | null;
  readonly text: string | null;
  readonly " $fragmentType": "NavBarButtons_buttons";
}>;
export type NavBarButtons_buttons$key = ReadonlyArray<{
  readonly " $data"?: NavBarButtons_buttons$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavBarButtons_buttons">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "NavBarButtons_buttons",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interactionType",
      "storageKey": null
    }
  ],
  "type": "NavBarButton",
  "abstractKey": null
};

(node as any).hash = "aff9ca1405199ac46586aeddc013e9e4";

export default node;

import { type FC, useState, useContext, useEffect } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Button } from 'dibs-elements/exports/Button';
import PlusCircle from 'dibs-icons/exports/PlusCircle';
import { Tooltip } from 'dibs-elements/exports/Tooltip';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import BulkItemUploadModalSuspense from 'dibs-bulk-item-upload/exports/BulkItemUploadModalSuspense';
import { type VerticalType } from 'dibs-bulk-item-upload/exports/types';
import { UserContext } from '../../UserContext';
import { trackNavInteraction } from '../../helpers/tracking';

import classnames from 'classnames';
import dibsCss from 'dibs-css';
import styles from './styles/DesktopSideBarNav.scss';

import { type DesktopSideBarCreateListing_seller$key } from './__generated__/DesktopSideBarCreateListing_seller.graphql';
import { type DesktopSideBarCreateListing_buttons$key } from './__generated__/DesktopSideBarCreateListing_buttons.graphql';

type Props = {
    seller: DesktopSideBarCreateListing_seller$key;
    buttons: DesktopSideBarCreateListing_buttons$key;
};

const MSKU_LISTING = 'msku-listing';

const sellerFragment = graphql`
    fragment DesktopSideBarCreateListing_seller on Seller {
        vertical
        createListingOptions(userId: $userId) {
            dataTn
            text
            url
            eventName
        }
    }
`;

const buttonsFragment = graphql`
    fragment DesktopSideBarCreateListing_buttons on NavBarButton @relay(plural: true) {
        text
        href
        eventName
        interactionType
    }
`;

export const DesktopSideBarCreateListing: FC<Props> = ({
    seller: sellerRef,
    buttons: buttonsRef,
}) => {
    const seller = useFragment(sellerFragment, sellerRef);
    const buttons = useFragment(buttonsFragment, buttonsRef);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (isMenuVisible) {
            trackNavInteraction({
                action: 'create listing dropdown',
                label: 'create listing',
                interactionType: 'drop down',
                isImpression: true,
            });
        }
    }, [isMenuVisible]);

    const sellerId = userContext.seller?.id;
    const vertical = (seller?.vertical || null) as VerticalType;

    const { createListingOptions = [] } = seller || {};
    const multipleOptions = (createListingOptions || []).length > 1;
    const hasMskuListingOption = createListingOptions?.some(
        option => option?.dataTn === MSKU_LISTING
    );

    const { text, href, eventName, interactionType } = buttons?.[0] || {};

    return (
        <div className={classnames(dibsCss.mxSmall, dibsCss.relative)}>
            <Button
                type="secondary"
                size="medium"
                fullWidth
                href={href || ''}
                onClick={e => {
                    if (multipleOptions) {
                        e.preventDefault();
                        setIsMenuVisible(prev => !prev);
                    }
                    if (eventName) {
                        trackNavInteraction({
                            action: eventName,
                            label: 'none',
                            interactionType,
                        });
                    }
                }}
            >
                <div className={classnames(dibsCss.flex, dibsCss.justifyCenter, dibsCss.gapXsmall)}>
                    <span className={styles.plusIcon}>
                        <PlusCircle variation="20" />
                    </span>
                    <span className={dibsCss.sassyFontBodyTypeHeavySizeSmall}>{text}</span>
                </div>
            </Button>
            <Tooltip
                isVisible={isMenuVisible}
                direction="bottom"
                paddingSize="none"
                closeOnOutsideClick
                onClose={() => setIsMenuVisible(false)}
            >
                <div
                    className={classnames(
                        dibsCss.flex,
                        dibsCss.flexCol,
                        dibsCss.pSmall,
                        dibsCss.gapXsmall
                    )}
                >
                    {hasMskuListingOption && (
                        <div
                            className={classnames(
                                dibsCss.sassyFontSubHeaderTypeHeavy,
                                dibsCss.mbXsmall
                            )}
                        >
                            <FormattedMessage
                                id="dal.navItems.createListingToooltip.question"
                                defaultMessage="What type of listing do you want to create?"
                            />
                        </div>
                    )}
                    {createListingOptions?.map(option => {
                        if (!option) {
                            return null;
                        }

                        const bulkUploadOption = option?.dataTn === 'bulk-upload';
                        const showDivider = bulkUploadOption && hasMskuListingOption;

                        return (
                            <div
                                key={option.dataTn}
                                className={classnames(dibsCss.border0, {
                                    [dibsCss.ptXsmall]: showDivider,
                                    [dibsCss.borderT]: showDivider,
                                    [dibsCss.borderSolid]: showDivider,
                                    [dibsCss.borderDolphin]: showDivider,
                                })}
                            >
                                <Link
                                    href={option.url || ''}
                                    onClick={e => {
                                        if (bulkUploadOption) {
                                            e.preventDefault();
                                            setBulkUploadModalOpen(true);
                                            setIsMenuVisible(false);
                                        }
                                        trackNavInteraction({
                                            action: option.eventName,
                                            label: option.eventName,
                                            interactionType: 'drop down',
                                        });
                                    }}
                                >
                                    <span>{option.text}</span>
                                    <ArrowRight
                                        className={classnames(
                                            styles.listingOptionIcon,
                                            dibsCss.h10px,
                                            dibsCss.ml3px
                                        )}
                                    />
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </Tooltip>
            {isBulkUploadModalOpen && (
                <BulkItemUploadModalSuspense
                    vertical={vertical}
                    sellerId={sellerId || null}
                    onClose={() => {
                        setBulkUploadModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

/**
 * @generated SignedSource<<89eef402549a5acc3ea12584c1a27656>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarNavSupportItem_item$data = {
  readonly eventName: string | null;
  readonly href: string | null;
  readonly interactionType: string | null;
  readonly title: string | null;
  readonly type: string | null;
  readonly " $fragmentType": "DesktopSideBarNavSupportItem_item";
};
export type DesktopSideBarNavSupportItem_item$key = {
  readonly " $data"?: DesktopSideBarNavSupportItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarNavSupportItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarNavSupportItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interactionType",
      "storageKey": null
    }
  ],
  "type": "NavBarListItem",
  "abstractKey": null
};

(node as any).hash = "af927f08da02153705c54ff5a49dcdab";

export default node;

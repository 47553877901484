/**
 * @generated SignedSource<<a02c897a8b9e1eec16e88b871a2b4d0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarExpandingAreaItem_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItemVATReportLink_seller" | "DesktopSideBarItem_seller">;
  readonly " $fragmentType": "DesktopSideBarExpandingAreaItem_seller";
};
export type DesktopSideBarExpandingAreaItem_seller$key = {
  readonly " $data"?: DesktopSideBarExpandingAreaItem_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarExpandingAreaItem_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarExpandingAreaItem_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItem_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItemVATReportLink_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "58a73a512f83cbf14a14f4d922bbcb38";

export default node;

import { type FC, useEffect } from 'react';
import { useIntl, FormattedMessage, defineMessages } from 'dibs-react-intl';

import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { Button } from 'dibs-elements/src/Button';

import { setBrowserNotificationsData } from './helpers';
import {
    trackModalPermissionAction,
    trackNotificationSystemPrompt,
    trackBrowserNotification,
} from './tracking';
import styles from './BrowserNotificationsSignUpModal.scss';

const messages = defineMessages({
    alt: {
        id: 'browserNotifications.alt',
        defaultMessage: 'screenshot of a 1stDibs browser notification',
    },
    notification: {
        id: 'browserNotifications.message',
        defaultMessage: "You're all signed up for notifications!",
    },
});

type Props = {
    showModal: boolean;
    onSuccess?: () => void;
    onClose: () => void;
};

const BrowserNotificationsSignUpModal: FC<Props> = ({ showModal, onClose, onSuccess }) => {
    useEffect(() => {
        trackModalPermissionAction('Displayed', 'Modal Displayed');
    }, []);
    const intl = useIntl();

    const closeModal = (): void => {
        setBrowserNotificationsData();
        trackModalPermissionAction('Clicked', 'Close');
        onClose();
    };

    return (
        <ModalContainer
            dataTn="browser-notifications-sign-up"
            isOpen={showModal}
            onClose={closeModal}
        >
            <ModalCloseButton onClick={closeModal} />
            <div className={styles.header}>
                <FormattedMessage id="browserNotifications.alert" defaultMessage="New" />
            </div>
            <BarHeader
                title={
                    <FormattedMessage
                        id="browserNotifications.title"
                        defaultMessage="Don’t Miss a Sale with Browser Notifications"
                    />
                }
            />
            <ModalBody>
                <p>
                    <FormattedMessage
                        id="browserNotifications.body"
                        defaultMessage="Get notifications from your browser when you receive a new message or order so you can respond as quickly as possible. Click the button below where you’ll be prompted to enable notifications."
                    />
                </p>
                <div className={styles.imageContainer}>
                    <img
                        src="https://a.1stdibscdn.com/dist/adhoc/sellers/notifications-signup/allow-notifications-drop-shadow.png"
                        className={styles.image}
                        alt={intl.formatMessage(messages.alt)}
                    />
                </div>
                <Button
                    type="secondary"
                    fullWidth
                    onClick={async () => {
                        trackModalPermissionAction('Clicked', 'Allow Notifications');
                        const permission = await window.Notification?.requestPermission?.();
                        const permissionIsGranted = permission === 'granted';
                        trackNotificationSystemPrompt(permissionIsGranted ? 'Allow' : 'Reject');

                        if (permissionIsGranted) {
                            new Notification(intl.formatMessage(messages.notification), {
                                icon: 'https://a.1stdibscdn.com/dist/adhoc/logo/seller-logo.png',
                            });
                            trackBrowserNotification('Sign Up');

                            if (onSuccess) {
                                onSuccess();
                            }
                        }

                        closeModal();
                    }}
                >
                    <FormattedMessage
                        id="browserNotifications.cta"
                        defaultMessage="Next: Allow Notifications"
                    />
                </Button>
            </ModalBody>
        </ModalContainer>
    );
};

export default BrowserNotificationsSignUpModal;

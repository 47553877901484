/**
 * @generated SignedSource<<4c21086e9885ab8bf73ddf0abb41e311>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarItemVATReportLink_navItem$data = {
  readonly eventName: string | null;
  readonly href: string | null;
  readonly interactionType: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItem_navItem">;
  readonly " $fragmentType": "DesktopSideBarItemVATReportLink_navItem";
};
export type DesktopSideBarItemVATReportLink_navItem$key = {
  readonly " $data"?: DesktopSideBarItemVATReportLink_navItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarItemVATReportLink_navItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSideBarItemVATReportLink_navItem",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSideBarItem_navItem"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interactionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "NavBarElement",
  "abstractKey": "__isNavBarElement"
};

(node as any).hash = "656b234cdf5c491377bbd6b33b2f116e";

export default node;

import { requestSubscription, graphql, type Environment } from 'react-relay/legacy';
import { type Disposable } from 'relay-runtime';

import {
    type newMilestoneSellerSubscription,
    type NewMilestoneSellerSubscriptionInput,
} from './__generated__/newMilestoneSellerSubscription.graphql';

export default function newMilestoneSeller(
    environment: Environment,
    input: NewMilestoneSellerSubscriptionInput,
    onNext: (data?: newMilestoneSellerSubscription['response'] | null) => void
): Disposable {
    const subscription = graphql`
        subscription newMilestoneSellerSubscription($input: NewMilestoneSellerSubscriptionInput!) {
            newMilestoneSeller(input: $input) {
                milestone(userType: "SELLER") {
                    ... on Message {
                        conversationId
                        textBody
                        from {
                            userType
                        }
                    }
                    ... on Milestone {
                        transaction {
                            serviceId
                            item {
                                title
                            }
                        }
                    }
                }
                milestoneNotification {
                    serviceId
                    type
                    buyerName
                }
            }
        }
    `;

    return requestSubscription<newMilestoneSellerSubscription>(environment, {
        subscription,
        variables: { input },
        onNext,
    });
}

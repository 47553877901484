import {
    useState,
    type Dispatch,
    type SetStateAction,
    type MouseEventHandler,
    type ReactNode,
    type SyntheticEvent,
} from 'react';
import { interactionTypeConstants } from 'dibs-tracking';
import { trackNavInteraction, TRACK_ACTIONS } from '../../helpers/tracking';

export const toDataTn = (str?: ReactNode): string => {
    if (typeof str === 'string') {
        return (str || '').toString().toLowerCase().replace(/ /g, '');
    }
    return '';
};

export const use1stdibsContactModalOpenState = (
    href: string
): {
    isContactModalOpen: boolean;
    setIsContactModalOpen: Dispatch<SetStateAction<boolean>>;
    contactModalOpenHandler: MouseEventHandler<HTMLAnchorElement> &
        MouseEventHandler<HTMLDivElement> &
        MouseEventHandler<HTMLButtonElement>;
} => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    return {
        isContactModalOpen,
        setIsContactModalOpen,
        contactModalOpenHandler: (e: SyntheticEvent) => {
            if (window.location.pathname !== href) {
                trackNavInteraction({
                    action: TRACK_ACTIONS.SUPPORT,
                    label: 'contact 1stDibs',
                });
                e.preventDefault();
                setIsContactModalOpen(true);
            }
        },
    };
};

export const useSendFeedbackModalOpenState = (): {
    isSendFeedbackModalOpen: boolean;
    setIsSendFeedbackModalOpen: Dispatch<SetStateAction<boolean>>;
    sendFeedbackModalOpenHandler: MouseEventHandler<HTMLAnchorElement> &
        MouseEventHandler<HTMLDivElement> &
        MouseEventHandler<HTMLButtonElement>;
} => {
    const [isSendFeedbackModalOpen, setIsSendFeedbackModalOpen] = useState(false);
    return {
        isSendFeedbackModalOpen,
        setIsSendFeedbackModalOpen,
        sendFeedbackModalOpenHandler: (e: SyntheticEvent) => {
            trackNavInteraction({
                action: TRACK_ACTIONS.SUPPORT,
                label: 'send feedback',
                interactionType: interactionTypeConstants.INTERACTION_TYPE_SUPPORT,
            });
            e.preventDefault();
            setIsSendFeedbackModalOpen(true);
        },
    };
};

export const useVatReportModalOpenState = (
    href: string
): {
    isVatReportModalOpen: boolean;
    setIsVatReportModalOpen: Dispatch<SetStateAction<boolean>>;
    vatReportModalHandler: (e: SyntheticEvent) => void;
} => {
    const openByDefault = window.location.hash === href;
    const [isVatReportModalOpen, setIsVatReportModalOpen] = useState(openByDefault);
    return {
        isVatReportModalOpen: isVatReportModalOpen,
        setIsVatReportModalOpen: setIsVatReportModalOpen,
        vatReportModalHandler: (e: SyntheticEvent) => {
            e.preventDefault();
            setIsVatReportModalOpen(true);
        },
    };
};

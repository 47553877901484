import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type BarGraphProps = {
    className?: string;
};

const BarGraph: FunctionComponent<BarGraphProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'bar-graph'}
            viewBox="0 0 250 250"
        >
            <polygon points="249,205.2 249,36.9 239.9,36.9 239.9,205.2 189.1,205.2 189.1,36.1 180,36.1 180,205.2 129.1,205.2 129.1,70.7 120,70.7 120,205.2 69.2,205.2 69.2,99.9 60.1,99.9 60.1,205.2 9.2,205.2 9.2,36 0.1,36 0.1,205.2 0.1,205.2 0.1,214.3 249.1,214.3 249.1,205.2" />
        </svg>
    );
};
export default BarGraph;

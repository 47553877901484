import { useState, useContext, useRef, useEffect, type FC } from 'react';
import * as React from 'react';
import { createFragmentContainer, graphql, type RelayProp } from 'react-relay/legacy';
import Logo from 'dibs-logo/exports/Logo';
import { breakpoints } from 'dibs-sassy/exports/breakpoints';
import classnames from 'classnames';

import InternalWrapper from './Nav/InternalWrapper';
import DealerWrapper from './Nav/DealerWrapper';
import { DesktopSideBarNav } from './Nav/DesktopSideBarNav';
import ResponsiveMenuToggles from './ResponsiveMenuToggles';
import { CustomHeaderMessage } from './CustomHeaderMessage';
import { UserContext } from '../UserContext';
import { trackNavInteraction } from '../helpers/tracking';
import useWindowWidth from '../hooks/useWindowWidth';
import { useBrandLink } from './userMenuOptions';
import styles from './styles/Header.scss';
import utilStyles from './styles/utils.scss';
import { isDirectlyLoggedInAsUser } from 'dibs-cookie-jar';
import { useSendPresenceHeartbeats } from 'dibs-presence/exports/useSendPresenceHeartbeats';
import { useIsSellerOnline } from 'dibs-presence/exports/useIsSellerOnline';
import { trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';
import { DEALER_DESKTOP_MIN_WIDTH, useSideBarNavTestContext } from '../hooks/useSideBarNavTest';
import { type NavHeader_viewer$data } from './__generated__/NavHeader_viewer.graphql';

type Props = {
    viewer?: NavHeader_viewer$data;
    customBannerText?: React.ReactNode;
    refetchIndex: number;
    relay: RelayProp;
};

const NavHeader: FC<Props> = props => {
    const { viewer, relay } = props;

    const customBannerMessage = props.customBannerText || viewer?.dealerNavBar?.bannerText || null;
    const defaultUserContext = useContext(UserContext);
    const [userContextState, setUserContextState] = useState(defaultUserContext);
    const setHasNotifications = (cond: boolean): void =>
        setUserContextState(prev => ({ ...prev, hasNotifications: cond }));
    const { isDealer, userOrSeller, masqueradeSeller, loginDealer } = userContextState;
    const seller = viewer?.seller;

    useSendPresenceHeartbeats({
        environment: relay.environment,
        viewer: viewer,
        seller: seller || null,
        isInternalUser: !isDealer,
    });
    const isOnline = useIsSellerOnline({
        environment: relay.environment,
        viewer: viewer || null,
        seller: seller,
    });
    const userMenuButtonRef = useRef<HTMLLIElement>(null);
    const windowWidth = useWindowWidth();
    const { showSideBarNav } = useSideBarNavTestContext();

    const isDealerLayout = isDealer || loginDealer;
    const mobileBreakPoint = isDealerLayout
        ? DEALER_DESKTOP_MIN_WIDTH
        : breakpoints.tabletLandscape.min;
    const isMobile = windowWidth < mobileBreakPoint;

    const [menuState, setMenuState] = useState({ userMenu: false, navMenu: false });
    const brandLink = useBrandLink();

    useEffect(() => {
        if (isDealer) {
            trackAbTestV2Variant('seller-aa-test-3');
            trackAbTestV2Variant('seller-aa-test-4');
            trackAbTestV2Variant('seller-aa-test-5');
        }
    }, [isDealer]);

    useEffect(() => {
        if (isDealer && !isMobile) {
            trackAbTestV2Variant('side-bar-nav');
        }
    }, [isDealer, isMobile]);

    const { userMenu: openUserMenu, navMenu: openNavBar } = menuState;
    const customHeader = customBannerMessage ? (
        <CustomHeaderMessage>{customBannerMessage}</CustomHeaderMessage>
    ) : null;

    const UserOrSellerNav = isDealerLayout ? DealerWrapper : InternalWrapper;
    const navType = isDealerLayout ? 'dealer' : 'internal';

    return (
        <UserContext.Provider
            value={{
                ...userContextState,
                setHasNotifications,
                isOnline,
                isPresenceEnabled: !!viewer?.presenceFeatureFlag,
            }}
        >
            <nav
                data-navtype={navType}
                className={classnames({
                    [styles.dealerNav]: isDealer,
                    [styles.internalNav]: !isDealer,
                    [styles.isInSideBarNavTest]: showSideBarNav,
                })}
                data-tn="top-navbar"
                id="js-header"
            >
                {showSideBarNav ? (
                    <DesktopSideBarNav viewer={viewer} />
                ) : (
                    <>
                        <div className={styles.navHeading}>
                            <a
                                data-tn="navbarBrandLink"
                                onClick={() =>
                                    isDealer
                                        ? trackNavInteraction({
                                              action: 'logo',
                                              label: 'none',
                                              interactionType: 'global nav',
                                          })
                                        : null
                                }
                                className={classnames(styles.brand, 'gtm-header-logo')}
                                href={brandLink}
                            >
                                <Logo className={styles.logo} />
                            </a>
                            {isMobile && (
                                <>
                                    {customHeader ? (
                                        customHeader
                                    ) : (
                                        <div className={utilStyles.flexSpacer} />
                                    )}
                                    {userOrSeller && (
                                        <ResponsiveMenuToggles
                                            isCustomHeader={!!customHeader}
                                            userMenuButtonRef={userMenuButtonRef}
                                            isMasquerading={!!masqueradeSeller}
                                            isDirectLogin={isDirectlyLoggedInAsUser(
                                                document.cookie
                                            )}
                                            menuState={menuState}
                                            setMenuState={setMenuState}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        {(userOrSeller || loginDealer) && (
                            <UserOrSellerNav
                                viewer={viewer}
                                isMobile={isMobile}
                                openNavBar={openNavBar}
                                refetchIndex={props.refetchIndex}
                                customHeader={customHeader || null}
                                openUserMenu={openUserMenu}
                            />
                        )}
                    </>
                )}
                <div id="nav-bottom-marker" />
            </nav>
        </UserContext.Provider>
    );
};

export default createFragmentContainer(NavHeader, {
    viewer: graphql`
        fragment NavHeader_viewer on Viewer {
            presenceFeatureFlag: featureFlag(feature: "presence")
            ... @include(if: $isDealer) {
                ...DesktopSideBarNav_viewer
                ...DealerWrapper_viewer
                dealerNavBar(
                    sellerPk: $sellerPk
                    dotComHost: $dotComHost
                    hyperwalletLink: $hyperwalletLink
                    customBannerText: $customBannerText
                    userId: $userId
                ) {
                    bannerText
                }
            }
            seller(sellerId: $sellerPk) @include(if: $hasSellerPk) {
                ...useSendPresenceHeartbeats_seller
                ...useIsSellerOnline_seller
            }
            ... @skip(if: $isDealer) {
                ...InternalWrapper_viewer
            }
            ...useSendPresenceHeartbeats_viewer
            ...useIsSellerOnline_viewer
        }
    `,
});

import { useState, useEffect, type ComponentType } from 'react';
import classnames from 'classnames';

import { CalendarDay } from './CalendarDay';
import { CalendarWeek } from './CalendarWeek';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';

import {
    subMonths,
    addMonths,
    getNamesOfWeekDays,
    getCalendarMonthWeeks,
    getDate,
    isCurrentMonth,
    getFirstDayOfWeek,
    getFormattedMonthTitle,
} from './helpers/datesHelper';

import styles from './styles/CalendarMonth.scss';

type Props = {
    disablePastDates: boolean;
    disableFutureDates: boolean;
    disablePreviousMonths: boolean;
    firstAvailableDate?: Date;
    firstDayOfWeek?: number;
    initialVisibleMonth?: Date;
    lastAvailableDate?: Date;
    onDayClick: (day: Date) => void;
    value?: string;
};

export const CalendarMonth: ComponentType<Props> = ({
    disablePastDates,
    disableFutureDates,
    onDayClick = () => {},
    initialVisibleMonth,
    firstDayOfWeek = getFirstDayOfWeek(),
    disablePreviousMonths,
    firstAvailableDate,
    lastAvailableDate,
    value,
}: Props) => {
    const valueDate = value ? new Date(value) : null;
    const [selectedDate, setSelectedDate] = useState<Date | null>(valueDate);
    const initialDate = valueDate || initialVisibleMonth || firstAvailableDate;
    const [focusedDate, setFocusedDate] = useState(getDate(initialDate));

    //unselect date if external clear function is used
    useEffect(() => {
        if (!value) {
            setSelectedDate(null);
        }
    }, [value]);

    function handlePrevMonthClick(disablePreviousClick: boolean): void {
        if (disablePreviousClick) {
            return;
        }

        const newDate = subMonths(focusedDate, 1);
        setFocusedDate(newDate);
    }

    function handleNextMonthClick(): void {
        const newDate = addMonths(focusedDate, 1);
        setFocusedDate(newDate);
    }

    function handleDayClick(d: Date): void {
        setFocusedDate(d);
        setSelectedDate(d);
        onDayClick(d);
    }

    const monthTitle = getFormattedMonthTitle(focusedDate);
    const nameOfWeekDays = getNamesOfWeekDays(firstDayOfWeek);
    const weeks = getCalendarMonthWeeks(
        focusedDate,
        firstDayOfWeek,
        disablePastDates,
        disableFutureDates,
        selectedDate,
        firstAvailableDate,
        lastAvailableDate
    );
    const disablePreviousClick = disablePreviousMonths && isCurrentMonth(focusedDate);

    return (
        <div className={styles.container}>
            <div className={styles.header} data-tn="date-picker-header">
                <div
                    onClick={() => handlePrevMonthClick(disablePreviousClick)}
                    className={styles.arrowWrapper}
                >
                    <ArrowLeft
                        className={classnames(styles.arrow, {
                            [styles.disabled]: disablePreviousClick,
                        })}
                    />
                </div>
                {monthTitle}
                <div className={styles.arrowWrapper} onClick={handleNextMonthClick}>
                    <ArrowRight className={styles.arrow} />
                </div>
            </div>
            <div className={styles.weekDaysContainer}>
                {nameOfWeekDays.map((day, i) => (
                    <div key={i} className={styles.weekDay}>
                        {day}
                    </div>
                ))}
            </div>
            {weeks.map((week, i) => (
                <CalendarWeek key={i} isFirstMonthWeek={i === 0}>
                    {week.map((date, dayOfWeek) => {
                        return (
                            <CalendarDay
                                key={dayOfWeek}
                                day={date.day}
                                selected={date.selected}
                                isToday={date.isToday}
                                disabled={date.disabled}
                                onDayClick={handleDayClick}
                                isOutside={date.isOutside}
                            />
                        );
                    })}
                </CalendarWeek>
            ))}
        </div>
    );
};

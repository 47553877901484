/**
 * @generated SignedSource<<a23a52016eba58d2271925105fc64964>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Nav_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DalMobileNav_seller" | "DesktopNav_seller">;
  readonly " $fragmentType": "Nav_seller";
};
export type Nav_seller$key = {
  readonly " $data"?: Nav_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Nav_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DalMobileNav_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopNav_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "af0c9906b2b0d603b1bcf6e9cebb97bf";

export default node;

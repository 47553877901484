import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';
import { SendFeedbackWindow } from '../../../components/SendFeedbackWindow';
import dibsCss from 'dibs-css';
import { FormattedMessage } from 'dibs-react-intl';
import { ModalContainer, type ModalContainerProps } from 'dibs-elements/exports/ModalContainer';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';

type SendFeedbackModalProps = ModalContainerProps & {
    type?: 'default';
    title?: ReactNode;
    iframeClassName?: string;
    feedBackSurveyUrl: string;
    onSubmit?: () => void;
    additionalParams?: Record<string, string>;
};

const defaultTitle = (
    <FormattedMessage id="dal.SendFeedbackModal.sendFeedback" defaultMessage="Send Feedback" />
);

export const SendFeedbackModal: FC<SendFeedbackModalProps> = ({
    feedBackSurveyUrl,
    title = defaultTitle,
    modalPosition = 'center',
    iframeClassName,
    onClose,
    onSubmit,
    isOpen,
    additionalParams,
    ...restProps
}) => (
    <ModalContainer
        dataTn="send-feedback-modal"
        modalPosition={modalPosition}
        onClose={onClose}
        isOpen={isOpen}
        {...restProps}
    >
        <ModalCloseButton onClick={onClose} />
        <BarHeader title={title} />
        <ModalBody className={classNames(dibsCss.flex, dibsCss.justifyCenter)}>
            <SendFeedbackWindow
                feedBackSurveyUrl={feedBackSurveyUrl}
                isVisible={isOpen}
                className={iframeClassName}
                additionalParams={additionalParams}
                onSubmit={onSubmit}
            />
        </ModalBody>
    </ModalContainer>
);

import { Fragment, type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import NavItem from '../NavItems/NavItem';
import NavDropdownItem from '../NavItems/NavDropdownItem';
import { useUserMenuOptions } from '../userMenuOptions';
import { prepareTrackEvent } from '../../helpers/tracking';

import { type DalAccountNav_accountNav$data } from './__generated__/DalAccountNav_accountNav.graphql';
import { type DalAccountNav_seller$data } from './__generated__/DalAccountNav_seller.graphql';

type Props = {
    isMobile: boolean;
    accountNav: DalAccountNav_accountNav$data | null | undefined;
    seller: DalAccountNav_seller$data | null | undefined;
};

const DalAccountNav: FC<Props> = ({ isMobile, accountNav, seller }) => {
    const [userMenu1, userMenu2] = useUserMenuOptions(!!(accountNav || []).length);

    return (
        <Fragment>
            {userMenu1.map((navItem, i) =>
                isMobile ? (
                    <NavItem
                        key={`userNav-b${i}`}
                        isMobile={isMobile}
                        seller={seller}
                        notifications={null}
                        navItem={null}
                        customNavItem={navItem}
                    />
                ) : (
                    <NavDropdownItem
                        key={`userNav-b${i}`}
                        seller={seller}
                        customNavItem={navItem}
                        navItem={null}
                    />
                )
            )}
            {(accountNav || []).map((navItem, i) =>
                isMobile ? (
                    <NavItem
                        key={`userNav-${i}`}
                        seller={seller}
                        isMobile={isMobile}
                        notifications={null}
                        navItem={navItem}
                    />
                ) : (
                    <NavDropdownItem
                        seller={seller}
                        key={`userNav-${i}`}
                        navItem={navItem}
                        trackEvent={prepareTrackEvent(navItem.eventName, navItem.interactionType)}
                    />
                )
            )}
            {userMenu2.map((navItem, i) =>
                isMobile ? (
                    <NavItem
                        key={`userNav-c${i}`}
                        isMobile={isMobile}
                        notifications={null}
                        seller={seller}
                        navItem={null}
                        customNavItem={navItem}
                    />
                ) : (
                    <NavDropdownItem
                        key={`userNav-c${i}`}
                        seller={seller}
                        customNavItem={navItem}
                        navItem={null}
                        trackEvent={prepareTrackEvent(navItem.eventName, navItem.interactionType)}
                    />
                )
            )}
        </Fragment>
    );
};

export default createFragmentContainer(DalAccountNav, {
    seller: graphql`
        fragment DalAccountNav_seller on Seller {
            ...NavItem_seller
            ...NavDropdownItem_seller
        }
    `,
    accountNav: graphql`
        fragment DalAccountNav_accountNav on NavBarElement @relay(plural: true) {
            eventName
            interactionType
            ...NavItem_navItem
            ...NavDropdownItem_navItem
        }
    `,
});

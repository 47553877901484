import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type CalendarProps = {
    className?: string;
    showNumber?: boolean;
};

const Calendar: FunctionComponent<CalendarProps> = ({ className, showNumber = true }) => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, className)}
            data-tn={'calendar'}
            viewBox="0 0 250 250"
        >
            <path d="M219.9 21h-40v-5.7c0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5V21h-98v-5.7c0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5V21H29C12.7 21-.5 34.2-.5 50.5v158.3c0 16.3 13.2 29.5 29.5 29.5h190.9c16.3 0 29.5-13.2 29.5-29.5V50.5c0-16.3-13.3-29.5-29.5-29.5zM29 30h34.8v22.7c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5V30h98v22.7c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5V30h40c11.3 0 20.5 9.2 20.5 20.5v31H8.5v-31C8.5 39.2 17.7 30 29 30zm190.9 199.3H29c-11.3 0-20.5-9.2-20.5-20.5V90.5h231.9v118.3c0 11.3-9.2 20.5-20.5 20.5z" />
            {showNumber && (
                <polygon points="104.9,143.8 109.9,148.9 120.6,137.6 120.6,193.3 128.9,193.3 128.9,126.6 121.6,126.6" />
            )}
        </svg>
    );
};
export default Calendar;
